import {
    ActionPlatform,
    ActiveHour,
    Company,
    CompanyLocale,
    CompanySubscriptionSettings,
    ContactInformation,
    SEOAddress,
    SEOItemList,
    SEOListItem,
    SEOOpeningHoursSpecification,
    SEOOrganization,
    SEOProduct,
    SEORestaurant,
    SEOSchemaDayOfWeek,
    SEOViewAction,
    SEOWebPage,
    Shop,
    Subscription,
    GroupLandingPage,
    GroupLandingCompany,
    CountryLocaleId
} from "Types";
import { formatShopUrlName } from "Utils";

/**
 * [FUNCTION] Create SEO Restaurant Object
 * @param shop
 * @param shopUrl
 * @param companyLocale
 * @returns
 */
//@ts-ignore
export const createOnlineOrderSEO = (shop: Shop, shopUrl: string, companyLocale: CompanyLocale): SEORestaurant => {
    const { contactInformation } = shop;

    /** Shop Home view + online order use this function
     * Need to swap out url ending for order when it comes to menu
     */
    const isShopHomeView = shopUrl.endsWith("/home");

    return {
        "@context": "https://schema.org",
        "@type": "Restaurant",
        "@id": shopUrl,
        name: shop.name,
        description: {
            sv: `Beställ online med qopla från ${shop.name} ${contactInformation.addressLine} i ${contactInformation.city}`,
            en: `Order online with qopla from ${shop.name} ${contactInformation.addressLine} in ${contactInformation.city}`
        },
        url: shopUrl,
        telephone: contactInformation?.phoneNumber || contactInformation?.phoneNumber2,
        email: contactInformation?.email,
        menu: !isShopHomeView ? shopUrl : shopUrl.replace("/home", "/order"),
        currenciesAccepted: companyLocale?.currency,
        paymentAccepted: "Credit Card, Swish",
        logo: shop?.imageUrl,
        image: shop?.bannerUrl,
        //@ts-ignore
        address: createSEOAddress(contactInformation, companyLocale.region),
        openingHoursSpecification: createSEOOpeningHours(shop.activeHours)
    };
};

/**
 * [FUNCTION] Create SEO Address object
 * @param contactInformation
 * @param region
 * @returns
 */
const createSEOAddress = (contactInformation: ContactInformation, region: string = "SE"): SEOAddress => {
    return {
        "@type": "PostalAddress",
        addressCountry: region,
        streetAddress: contactInformation?.addressLine,
        addressLocality: contactInformation?.city,
        postalCode: contactInformation?.postCode
    };
};

/**
 * [TEST_SUITE]
 * [FUNCTION] Create SEO opening hours
 *
 * * SEOSchemaDayOfWeek has keys that match each of the dayOfWeek in active day
 * * Keyof typeof - is used when typescripting and converting a string to an enum key
 * * In the case of dayOrWeek they are all string that are capitalised
 *
 * @param activeHours
 * @returns
 */
export const createSEOOpeningHours = (activeHours: ActiveHour[]): SEOOpeningHoursSpecification[] => {
    return activeHours
        .filter(activeDay => !activeDay.closed)
        .map((activeDay: ActiveHour) => {
            return {
                "@type": "OpeningHoursSpecification",
                dayOfWeek: SEOSchemaDayOfWeek[activeDay.dayOfWeek as keyof typeof SEOSchemaDayOfWeek],
                opens: `${activeDay.startingHour}:00`,
                closes: `${activeDay.stoppingHour}:00`
            };
        });
};

/**
 * [FUNCTION] creating SEO Organistation
 * @param company
 * @param companyHomeUrl
 * @param currentOriginUrl
 * @returns
 */
export const createSEOOrganization = (
    company: Company,
    companyHomeUrl: string,
    currentOriginUrl: string
): SEOOrganization => {
    const { contactInformation, companyOnlineSettings, companyOnlineLandingPage } = company || {};
    const hasSocialMedia = !!companyOnlineSettings.facebook || !!companyOnlineSettings.instagram;

    let socialMediaLinks: string[] = [];

    if (hasSocialMedia) {
        if (!!companyOnlineSettings.facebook) {
            socialMediaLinks = [companyOnlineSettings.facebook];
        }
        if (!!companyOnlineSettings.instagram) {
            socialMediaLinks = [...socialMediaLinks, companyOnlineSettings.instagram];
        }
    }

    //@ts-ignore
    const companyLocale = new Intl.Locale(company.countryLocale.localeCode ?? CountryLocaleId.sv_SE);

    /** Set restaurant links for seo actions */
    const restaurantLinks = company?.shops
        ?.filter(shop => !!shop.name)
        .map(shop => {
            return `${currentOriginUrl}/restaurant/${formatShopUrlName(shop.name)}/${shop.publicId}/order`;
        });

    return {
        "@context": "https://schema.org",
        "@type": "Organization",
        name: company.name,
        description: companyOnlineLandingPage.breadText,
        image: companyOnlineLandingPage?.backgroundImageUrl,
        logo: company.imageUrl,
        ...(hasSocialMedia && socialMediaLinks),
        url: companyHomeUrl,
        telephone: contactInformation?.phoneNumber || contactInformation?.phoneNumber2,
        email: contactInformation?.email,
        //@ts-ignore
        ...(!!contactInformation && { address: createSEOAddress(contactInformation, companyLocale.region) }),
        //@ts-ignore
        ...(!!restaurantLinks && { potentialAction: createSEOActionLinks(restaurantLinks, companyLocale.baseName) })
    };
};

/**
 * [FUNCTION] set SEO for group landing page
 * @param groupLandingPage
 * @param groupCompanyOnlineLandingPages
 * @param groupShops
 * @param groupHomeUrl
 * @param currentOriginUrl
 * @returns
 */
export const createSEOGroupLanding = (
    groupLandingPage: GroupLandingPage,
    groupCompanyOnlineLandingPages: GroupLandingCompany[],
    groupShops: Shop[],
    groupHomeUrl: string,
    currentOriginUrl: string
): SEOOrganization => {
    const {
        name,
        landingPage: { headerText, backgroundImageUrl },
        logoUrl,
        facebook,
        instagram
    } = groupLandingPage;

    //TODO: find a way to get a proper company locale, even if sortedCompanyLandingPageIds is empty array
    let companyLocale = new Intl.Locale(CountryLocaleId.sv_SE);

    // groupCompanyOnlineLandingPages can be empty array and then we get an error, bug #3305
    if (groupCompanyOnlineLandingPages.length > 0) {
        const { countryLocale } = groupCompanyOnlineLandingPages[0];
        if (countryLocale?.localeCode) {
            companyLocale = new Intl.Locale(countryLocale?.localeCode);
        }
    }

    const hasSocialMedia = !!facebook || !!instagram;

    let socialMediaLinks: string[] = [];

    if (!!facebook) {
        socialMediaLinks = [facebook];
    }
    if (!!instagram) {
        socialMediaLinks = [...socialMediaLinks, instagram];
    }

    /** Set restaurant links for seo actions */
    const restaurantLinks = groupShops
        ?.filter(shop => !!shop.name)
        .map(shop => {
            return `${currentOriginUrl}/restaurant/${formatShopUrlName(shop.name)}/${shop.publicId}/order`;
        });

    return {
        "@context": "https://schema.org",
        "@type": "Organization",
        name: name,
        description: headerText,
        image: backgroundImageUrl || "",
        logo: logoUrl || "",
        url: groupHomeUrl,
        ...(hasSocialMedia && socialMediaLinks),
        //@ts-ignore
        ...(!!restaurantLinks && { potentialAction: createSEOActionLinks(restaurantLinks, companyLocale?.baseName) })
    };
};

/**
 * [FUNCTION] set SEO Action links with in language locale id
 *
 * * urlLinks - pass in a string of links
 */
export const createSEOActionLinks = (urlLinks: string[], localeId?: string): SEOViewAction => {
    return {
        "@type": "ViewAction",
        target: urlLinks.map((url: string) => ({
            "@type": "EntryPoint",
            urlTemplate: url,
            actionPlatform: [ActionPlatform.Desktop, ActionPlatform.Mobile],
            ...(!!localeId && { inLanguage: localeId })
        }))
    };
};

/**
 * [FUNCTION] - setting the subscriptions of the subscription page
 * @param companyName
 * @param companySettings
 * @param subscriptions
 * @param currency
 * @param currentUrl
 * @returns
 */
export const createSEOCompanySubscriptionPage = (
    companyName: string,
    companySettings: CompanySubscriptionSettings,
    subscriptions: Subscription[],
    currency: string,
    currentUrl: string
): SEOWebPage => {
    return {
        "@context": "https://schema.org",
        "@type": "WebPage",
        name: companyName,
        description: companySettings.description,
        headline: companySettings.title,
        url: currentUrl,
        primaryImageOfPage: {
            "@type": "ImageObject",
            contentUrl: companySettings.imageUrl
        },
        ...(!!subscriptions.length && {
            mainEntity: createSEOSubscriptionsItemList(subscriptions, currentUrl, currency, companySettings.imageUrl)
        })
    };
};

/**
 * [FUNCTION] Setting subscriptions into a list or products
 * @param subscriptions
 * @param currentUrl
 * @param currency
 * @param defaultImage
 * @returns
 */
const createSEOSubscriptionsItemList = (
    subscriptions: Subscription[],
    currentUrl: string,
    currency: string,
    defaultImage: string
): SEOItemList => {
    const subscriptionItemList = subscriptions.map((sub: Subscription, index: number) => {
        const pricePlan = sub.pricePlans[0];
        return {
            "@type": "ListItem",
            position: `${index + 1}`,
            item: {
                "@context": "https://schema.org",
                "@type": "Product",
                image: sub.imageUrl || pricePlan.imageUrl || defaultImage,
                name: sub.name,
                description: sub.description,
                offers: {
                    priceCurrency: currency,
                    price: `${pricePlan.amount}`
                }
            } as SEOProduct
        };
    }) as SEOListItem[];

    return {
        "@context": "https://schema.org",
        "@type": "ItemList",
        url: currentUrl,
        numberOfItems: `${subscriptions.length}`,
        itemListElement: subscriptionItemList
    };
};
