import { OnlineEatingPreference } from "Types";
import { ThirdPartyDeliveryType } from "Constants";

export enum BrowseOptions {
    IS_CLOSED = "isClosed",
    IS_PAUSED = "isPaused",
    IS_TEMPORARILY_OFFLINE = "isTemporarilyOffline",
    HAS_NO_ACTIVE_MENUS = "hasNoActiveMenus",
    CLOSED_ONLINE_ORDERS = "closedOnlineOrders",
    RUSH_HOUR = "rushHour",
    GLOBALLY_CLOSED = "globallyClosed"
}

export enum CustomerType {
    Organization = "organization",
    Private = "private"
}

export type OnlinePickupOptions = {
    time: string | "";
    date: string | "";
    isEarliest: boolean;
};

export type DeliveryInformation = {
    addressLine?: string;
    postCode: string;
    city?: string;
    country?: string;
    fee: number;
    street?: string;
    willDeliverCatering: boolean;
    customerType: CustomerType;
    thirdPartyDelivery: ThirdPartyDeliveryType | null;
    timeInterval?: string;
    latLng?: {
        lat: string;
        lng: string;
    };
};

export type CateringInformation = {
    earliestHoursToConfirmCatering: number;
};

export type FoodOptions = {
    eatingPreference: OnlineEatingPreference;
    pickupOptions: OnlinePickupOptions;
    deliveryInformation: DeliveryInformation | null;
    cateringInformation: CateringInformation | null;
};
