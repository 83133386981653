import { getCurrencySymbol } from "PriceUtils";
import { AlcoholTag } from "./productTypes";

/**
 * [NEW_COUNTRY] - When adding a new country
 *
 * 1. Add country name to 'CountryName'
 * 2. Add country locale id to 'CountryLocaleId'
 * 3. Add country currency symbol to 'CurrencySymbol'
 * 4. Add region timezone to 'RegionTimeZone'
 * 5. Check VAT rates for country
 *      i. Add a standard (food) 'StandardCountryVat'
 *     ii. Add a standard vat rates 'CountryVatRates'
 * 6. Put together 'CountryLocales' using Country name as the key (follow others)
 * 7. Finally use enum 'CountryName' key as the translation key in each of the files
 *
 * ** I think in the future I would like to take these out of here
 * ** and set them in a collection with a page for super admin
 * ** get the collection on customer registration
 */

/** Country Types to use */
export type CountryLocale = {
    localeCode: CountryLocaleId | string;
    currencyCode: CurrencySymbol | string;
};

export type CountryToLocales = {
    [key in keyof typeof CountryName]: CountryLocale;
};

/** Country enums
 *
 * 1. Country Name
 * used in customer registration and translations
 */
export enum CountryName {
    SWEDEN = "SWEDEN",
    FINLAND = "FINLAND",
    DENMARK = "DENMARK",
    GERMANY = "GERMANY"
}

/**
 * 2. Country Locale id
 * Used to save company locale code and to access enum 3 currency
 * either in customer registration or company general settings
 */
export enum CountryLocaleId {
    fi_FI = "fi-FI",
    sv_SE = "sv-SE",
    da_DK = "da-DK",
    de_DE = "de-DE"
}

/**
 * 3. Currency symbol
 * Used to save company locale code
 * either in customer registration or company general settings
 */
export enum CurrencySymbol {
    sv_SE = "SEK",
    fi_FI = "EUR",
    da_DK = "DKK",
    de_DE = "EUR"
}

/**
 * 4. Timezones
 * TBC.. Issues using timezone (Locale) & firefox
 * Good to have for the future
 */
export enum RegionTimeZone {
    SE = "Europe/Stockholm",
    FI = "Europe/Helsinki",
    DK = "Europe/Copenhagen",
    DE = "Europe/Berlin"
}

/**
 * Step 5
 * Standard Vat per country on food
 * * e.g how to get one from locale
 * * If VAT is changed for Sweden then make sure Alcohol Record below is changed
 * @example StandardCountryVat[countryLocale.localeCode as CountryLocaleId]
 */
export const StandardCountryVat: { [key in CountryLocaleId]: number } = {
    "sv-SE": 12,
    "fi-FI": 14,
    "da-DK": 25,
    "de-DE": 7
};

/**
 * Step 5
 * Standard Vat rates per country
 * * e.g how to get one from locale
 * * If VAT is changed for Sweden then make sure Alcohol Record below is changed
 * @example CountryVatRates[countryLocale.localeCode as CountryLocaleId]
 
 */
export const CountryVatRates: { [key in CountryLocaleId]: number[] } = {
    "sv-SE": [12, 25, 6, 0],
    "fi-FI": [14, 24, 10, 0],
    "da-DK": [25],
    "de-DE": [7, 19, 0]
};

/**
 * Step 6
 * Used in customer registration to set country locale on form submit
 */
export const CountryLocales: CountryToLocales = {
    SWEDEN: { localeCode: CountryLocaleId.sv_SE, currencyCode: CurrencySymbol.sv_SE },
    FINLAND: { localeCode: CountryLocaleId.fi_FI, currencyCode: CurrencySymbol.fi_FI },
    DENMARK: { localeCode: CountryLocaleId.da_DK, currencyCode: CurrencySymbol.da_DK },
    GERMANY: { localeCode: CountryLocaleId.de_DE, currencyCode: CurrencySymbol.de_DE }
};

//NOTE TIMEZONE IS NOT COMPATIABLE ON FIREFOX - if we need it
/**
 * Used for Qopla store over the back office for setting financial numbers etc
 */
//@ts-ignore
export class CompanyLocale extends Intl.Locale {
    timeZone!: RegionTimeZone | undefined;
    currency!: string;
    currencySymbol!: string | undefined;
    //@ts-ignore
    constructor(tag?: string | Intl.Locale, options?: Intl.LocaleOptions | undefined) {
        super(tag ? tag : CountryLocaleId.sv_SE, options);
    }

    setTimeZone(regionTimeZone: RegionTimeZone) {
        this.timeZone = regionTimeZone;
    }

    setCurrency(currency: string) {
        this.currency = currency;
        //@ts-ignore
        this.currencySymbol = getCurrencySymbol(this);
    }
}

// SWEDISH ONLY
// ALCOHOL

/** Tags on higher rate tax */
export const alcoholTagHigherVatRate = [
    AlcoholTag.SPIRITS,
    AlcoholTag.STRONG_BEER,
    AlcoholTag.WINE,
    AlcoholTag.OTHER_ALCOHOLIC_DRINK
];

/** Tags on lower rate tax */
export const alcoholTagLowerVatRate = [AlcoholTag.LIGHT_BEER];

/** Record for vat rate to alcohol tags */
export const swedishVatRateToAlcoholTag: Record<number, AlcoholTag[]> = {
    25: alcoholTagHigherVatRate,
    12: alcoholTagLowerVatRate
}