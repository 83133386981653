import { UserRoles } from "Constants";
import { UserTypes } from "Providers";


export enum BackOfficeRouteRequirement {
	SELECTED_COMPANY = "selectedCompany",
	SELECTED_SHOP = "selectedShop"
}

export interface IRoute {
	path: string;
	component: any;
	container?: any;
	exact?: boolean;
	allowedRoles?: UserRoles[];
	footer?: React.ComponentClass;
	nav?: React.ComponentClass;
	title?: string;
	sentryChild?: React.ComponentClass;
	type?: string;
	unAuth?: React.FC<{}>;
	providers?: React.ReactElement[];
	sideNavigation?: any;
	authUserType?: UserTypes;
	hasToHave?: BackOfficeRouteRequirement[];
}