export enum ThirdPartyAccountingType {
    FORTNOX = "FORTNOX"
}

export namespace AccountAuthenticationQuery {
    export type SingleAccount = {
        getAccountAuthenticationByTypeAndCompany: AccountAuthenticationSettings;
    };
    export type MultiAccounts = {
        getAllAccountAuthenticationsByTypeAndCompany: AccountAuthenticationSettings[];
    };
}

export type AccountAuthenticationSettings = {
    id?: string;
    thirdPartyAccountingType: ThirdPartyAccountingType;
    accessToken?: string;
    refreshToken?: string;
    companyIds?: string[];
    shopIds?: string[];
};
