import {
    BundleProductCategory,
    SelectedBundleProductItem,
    RefProduct,
    MenuBundleProduct,
    PosProviderRefProduct,
    Modifications,
    Modification
} from "Types";

export const getBundleCategorySelectedBundleProductItems = (
    selectedBundleProductItems: SelectedBundleProductItem[],
    bundleCategoryId: string
) => selectedBundleProductItems?.filter(item => item.bundleProductCategoryId === bundleCategoryId);

export const bundleCategoryLimitReached = (
    selectedBundleProductItems: SelectedBundleProductItem[],
    bundleProductCategory: BundleProductCategory
) => {
    const selectedCategoryProducts = selectedBundleProductItems.filter(
        bundleItem => bundleItem.bundleProductCategoryId === bundleProductCategory.id && bundleItem.refProductId
    ).length;
    return selectedCategoryProducts === bundleProductCategory.limit;
};

export const getBundleItemQuantity = (
    refProductId: string,
    selectedBundleProductItems: SelectedBundleProductItem[] | null = [],
    bundleCategoryId: string
) => {
    return selectedBundleProductItems!.filter(selectedBundleProductItem => {
        const hasMatchingId = selectedBundleProductItem.refProductId === refProductId;
        const hasMatchingBundleCategoryId = selectedBundleProductItem.bundleProductCategoryId === bundleCategoryId;

        return hasMatchingId && hasMatchingBundleCategoryId;
    }).length;
};

export const getMenuBundleModificationsToUse = (
    refProduct: RefProduct | PosProviderRefProduct,
    menuBundleProduct: MenuBundleProduct
): Modifications | null => {
    let modificationsToUse = refProduct.modifications ? refProduct.modifications : null;
    if (menuBundleProduct.menuBundleModifications) {
        const mods = menuBundleProduct.menuBundleModifications.find(mod => mod.refProductId === refProduct.id);
        if (mods && mods.modifications) {
            //@ts-ignore
            modificationsToUse = {
                sizes: mods.modifications.sizes
                    ? (mods.modifications.sizes as Modification[]).map(size => ({
                          ...size,
                          price: 0
                      }))
                    : null,
                flavours: mods.modifications.flavours
                    ? (mods.modifications.flavours as Modification[]).map(flavour => ({
                          ...flavour,
                          price: 0
                      }))
                    : null
            };
        }
    }
    return modificationsToUse as Modifications;
};

export const getSingleBundleProductCategories = (bundleProductCategories: BundleProductCategory[]) =>
    bundleProductCategories.filter(({ refProductIdList }) => refProductIdList.length === 1);
