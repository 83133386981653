export type AuthenticatedUserProps = {
    companyId: string;
    roles: [string];
    shopIds: [string];
    token: string;
    userId: string;
    username: string;
};

export enum UserTypes {
    USER_ACCOUNT = "userAccount",
    COMPANY_USER = "companyUser"
}

export type UserAccountContactInformation = {
    email: string;
    name: string;
    lastName: string;
    phoneNumber: string;
    addressLine: string | null;
    postCode: number | null;
    floorNumber: string | null;
    doorCode: string | null;
    city: string;
};

export type UserAccount = {
    id: string;
    imageUrl: string;
    contactInformation: UserAccountContactInformation;
    isGoogleAccount: boolean;
    acceptsMarketing: boolean;
    hasMigratedHistoryOrders: boolean;
};