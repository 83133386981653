import ReactGA from "react-ga4";
import { Location } from "react-router-dom";

import { envConstants, excludedGAPaths, frontyEnv } from "Constants";
import { GoogleAnalyticsType } from "Types";

/**
 * [FUNCTION] create production only google analytics event
 * @param googleEvent
 * @returns
 */
export const gaProductionEvent = (googleEvent: GoogleAnalyticsType) => {
    try {
        return (
            envConstants.PROFILE === frontyEnv.PROD &&
            ReactGA.event({
                ...googleEvent
            })
        );
    } catch (error) {
        console.log("Google analytics error ", error);
    }
    return;
};

/**
 * [FUNCTION] create google analytics event in any profile
 * @param googleEvent
 * @returns
 */
export const gaEvent = (googleEvent: GoogleAnalyticsType) => {
    try {
        const currentProfile = `[${envConstants.PROFILE}]`;
        return ReactGA.event({
            ...googleEvent,
            label: !!googleEvent.label ? `${currentProfile} - ${googleEvent.label}` : currentProfile
        });
    } catch (error) {
        console.log("Google analytics error ", error);
    }
    return;
};

export const trackPageView = (location: Location) => {
    ReactGA.set({ page: location.pathname });
    ReactGA.send({ hitType: "pageview", page: location.pathname });
};

/**
 * @deprecated
 */
export const initGA = (location: Location) => {
    trackPageView(location);
   // history.listen(trackPageView);
};

export const canTrackPath = (pathname: string) =>
    !excludedGAPaths.some(excludedGAPath => pathname.includes(excludedGAPath));
