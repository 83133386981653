export const typography = {
    fontSizes: {
        "2xs": "0.625rem", // 10px
        xs: "0.75rem", // 12px
        sm: "0.875rem", // 14px
        md: "1rem", // 16px
        lg: "1.125rem", // 18px
        xl: "1.25rem", // 20px
        "2xl": "1.5rem", // 24px
        "3xl": "1.875rem", // 30px
        "4xl": "2.25rem", // 36px
        "5xl": "3rem", // 48px
        "6xl": "4rem" // 64px
    },
    fontWeights: {
        thin: 100,
        light: 300,
        normal: 400,
        bold: 700,
        black: 900
    },
    lineHeights: {
        normal: "normal",
        none: "1",
        shorter: "1.25",
        short: "1.375",
        base: "1.5",
        tall: "1.625",
        taller: "2"
    },
    fonts: {
        body: "'Lato', sans-serif",
        heading: "'Lato', sans-serif",
        qopla: "qopla",
        giftcard: "giftcard"
    }
};
