export enum POSITIVE_SHOP_FEEDBACK {
    GOOD_FOOD = "GOOD_FOOD",
    EXCELLENT_FOOD = "EXCELLENT_FOOD",
    QUICK_SERVICE = "QUICK_SERVICE",
    EXCELLENT_QUALITY = "EXCELLENT_QUALITY",
    GOOD_COMMUNICATION = "GOOD_COMMUNICATION",
    GOOD_VALUE = "GOOD_VALUE",
    PACKAGED_PROPERLY = "PACKAGED_PROPERLY",
    GOOD_PORTIONS = "GOOD_PORTIONS"
}

export enum NEGATIVE_SHOP_FEEDBACK {
    PACKAGED_BADLY = "PACKAGED_BADLY",
    INCOMPLETE_ORDER = "INCOMPLETE_ORDER",
    SERVICE_SLOW = "SERVICE_SLOW",
    BAD_FOOD = "BAD_FOOD",
    TOO_EXPENSIVE = "TOO_EXPENSIVE",
    BAD_COMMUNICATION = "BAD_COMMUNICATION",
    SMALL_PORTIONS = "SMALL_PORTIONS",
    COLD_FOOD = "COLD_FOOD"
}

export enum POSTIVE_QOPLA_FEEDBACK {
    EASY_TO_USE = "EASY_TO_USE",
    GOOD_NAVIGATION = "GOOD_NAVIGATION",
    EASY_PURCHASE = "EASY_PURCHASE",
    VISUALLY_APPEALING = "VISUALLY_APPEALING",
    WOULD_RECOMMEND = "WOULD_RECOMMEND",
    FAIRLY_EASY_TO_USE = "FAIRLY_EASY_TO_USE",
    OK_NAVIGATION = "OK_NAVIGATION",
    MAY_RECOMMEND = "MAY_RECOMMEND"
}

export enum NEGATIVE_QOPLA_FEEDBACK {
    PRICING_NOT_CLEAR = "PRICING_NOT_CLEAR",
    SLOW_LOADING = "SLOW_LOADING",
    DIFFICULTY_PURCHASING = "DIFFICULTY_PURCHASING",
    NOT_VISUALLY_APPEALING = "NOT_VISUALLY_APPEALING",
    NOT_RECOMMEND = "NOT_RECOMMEND",
    BAD_NAVIGATION = "BAD_NAVIGATION",
    NOT_SO_EASY_TO_USE = "NOT_SO_EASY_TO_USE",
    NOT_EASY_TO_USE = "NOT_EASY_TO_USE"
}

const TotalShopFeedback = { ...POSITIVE_SHOP_FEEDBACK, ...NEGATIVE_SHOP_FEEDBACK };
export type SHOP_FEEDBACK = typeof TotalShopFeedback;

const TotalQoplaFeedback = { ...POSTIVE_QOPLA_FEEDBACK, ...NEGATIVE_QOPLA_FEEDBACK };
export type QOPLA_FEEDBACK = typeof TotalQoplaFeedback;

export enum CustomerFeedbackType {
    GROUP_ORDER = "GROUP_ORDER",
    ONLINE_ORDER = "ONLINE_ORDER",
    GIFT_CARD = "GIFT_CARD"
}

export type CustomerFeedback = {
    id?: string;
    shopId: string;
    orderId: string;
    name?: string;
    userAccountId?: string;
    overallRating: number;
    feedback: SHOP_FEEDBACK[];
    productRatings?: ProductRating[];
    additionalComments?: string;
    qoplaReview?: QoplaReview;
    type?: CustomerFeedbackType;
    createdAt?: string;
    __typename?: "CustomerFeedback";
};

export type ProductRating = {
    name: string;
    bundleProductText?: string;
    refProductId?: string;
    refBundleProductId?: string | null;
    thumbsUp?: boolean;
    feedback?: string[];
};

export type QoplaReview = {
    overallRating: number;
    feedback: QOPLA_FEEDBACK[];
    additionalComments: string;
};
