import { ExtendedBundleProductCategory } from "OnlineTypes";
import {
    BundleProductCategory,
    MenuProductCategory,
    OnlineProductCategory,
    OnlineProducts,
    PosProviderRefProduct,
    PosProviderRefProductHashMap
} from "Types";

const getFirstImage = (menuProducts: any[], menuBundleProducts: any[]) => {
    const extractImageUrl = (items: any[], key: string) => {
        const item = items?.find(item => item[key]?.imageUrl || item[key]?.imageResizedUrls?.medium);
        return item?.[key]?.imageUrl || item?.[key]?.imageResizedUrls?.medium;
    };
    const firstRefProductImage = extractImageUrl(menuProducts, "refProduct");
    const firstRefBundleImage = extractImageUrl(menuBundleProducts, "refBundleProduct");
    return firstRefProductImage || firstRefBundleImage || null;
};

export const getCategoryImage = (menuProductCategory: MenuProductCategory) => {
    const { imageUrl, imageResizedUrls } = menuProductCategory;
    const categoryImage = imageResizedUrls?.medium || imageUrl;
    if (!!categoryImage) {
        return categoryImage;
    } else {
        return getFirstImage(menuProductCategory?.menuProducts, menuProductCategory.menuBundleProducts);
    }
};

/** THESE ARE FOR ONLINE PRODUCT CATEGORIES - I have both here due to difference in types used to collect images */
const extractImageUrl = (items: any[], key: string) => {
    const item = items?.find(item => item[key]?.imageUrl || item[key]?.imageResizedUrls?.medium);
    return item?.[key]?.imageUrl || item?.[key]?.imageResizedUrls?.medium;
};

const getFirstProductImage = (onlineProducts: OnlineProducts) => {
    /** Split into menu products and menu bundle product */
    const menuProducts = onlineProducts.filter(value => !!value.menuProduct).map(value => value.menuProduct);
    const menuBundleProducts = onlineProducts
        .filter(value => !!value.menuBundleProduct)
        .map(value => value.menuBundleProduct);
    /** Extract first image on both arrays */
    const firstRefProductImage = extractImageUrl(menuProducts, "refProduct");
    const firstRefBundleImage = extractImageUrl(menuBundleProducts, "refBundleProduct");
    return firstRefProductImage || firstRefBundleImage || null;
};

export const getOnlineCategoryImage = (menuProductCategory: OnlineProductCategory) => {
    return !!menuProductCategory?.imageUrl
        ? menuProductCategory.imageUrl
        : getFirstProductImage(menuProductCategory?.onlineProducts);
};

const transformRefProducts = (
    bundleCatsRefProductsList: string[],
    posCtxRefProducts: PosProviderRefProductHashMap
): PosProviderRefProduct[] => {
    return bundleCatsRefProductsList.reduce<PosProviderRefProduct[]>((acc, next) => {
        const refProduct = posCtxRefProducts[next];

        if (!!refProduct) {
            acc.push({
                ...refProduct,
                quantity: 0
            });
        }

        return acc;
    }, []);
};

export const buildBundleProductCategories = (
    bundleProductCategories: BundleProductCategory[],
    refProductsHashMap: PosProviderRefProductHashMap
): ExtendedBundleProductCategory[] => {
    let index = 0;
    return bundleProductCategories
        .map(cat => {
            const refProducts = transformRefProducts(cat.refProductIdList, refProductsHashMap);
            const refProductIdList = refProducts.map(refProduct => refProduct.id);

            return {
                ...cat,
                index: index++,
                refProducts,
                refProductIdList
            };
        })
        .sort((a, b) => a.sortOrder - b.sortOrder);
};
