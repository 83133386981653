import { PropertyPath } from "lodash";
import { clone, setWith } from "lodash/fp";

const filterArray = (array: any, filters: any) => {
    return array.filter((item: any) => {
        return Object.keys(filters).some(key => {
            if (typeof filters[key] !== "function") {
                throw new Error(`${filters[key]} is not a function. Each filter must be a function.`);
            }

            return filters[key](item[key]);
        });
    });
};

type PredicateFunc<T> = (val: T) => boolean;

const combineFilters = <T extends any>(...fns: PredicateFunc<T>[]): PredicateFunc<T> => val => {
    for (const filter of fns) {
        const passes = filter(val);
        if (!passes) {
            return false;
        }
    }
    return true;
};

const composeFilters = <T extends any>(...fns: [...PredicateFunc<T>[]]) => (array: T[]) =>
    array.filter(combineFilters<T>(...fns));

const setIn = (object: any, path: PropertyPath, value: any) => setWith(clone(object), path, value, clone(object));

export { filterArray, combineFilters, composeFilters, setIn };
