import axios from "axios";
import fileDownload from "js-file-download";

export type DownloadQrCodeParams = {
    url: string;
    fileName: string;
    size?: string;
    format?: string;
};

export const handleDownloadQrCode = ({ url, fileName, size = "1024", format = "png" }: DownloadQrCodeParams) => {
    axios
        .get(`https://api.qrserver.com/v1/create-qr-code/?size=${size}x${size}&data=${url}%3Fqr=1&format=${format}`, {
            responseType: "arraybuffer"
        })
        .then((response: any) => {
            const buffer = Buffer.from(response.data, "base64");
            fileDownload(buffer, fileName);
        });
};
