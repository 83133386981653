import { CompanySubscriptionSettings, CountryLocale } from "Types";
import { CreditCardInfo, Order } from "./orderTypes";

export enum SubscriptionStatus {
    ACTIVE = "ACTIVE",
    CANCELLED = "CANCELLED",
    AT_RISK = "AT_RISK",
    INITIAL_PAYMENT_FAILED = "INITIAL_PAYMENT_FAILED",
    PENDING = "PENDING"
}

export enum SubscriptionType {
    SINGLE = "SINGLE",
    GROUP = "GROUP"
}

export type SubscriptionCancelReason = {
    reason?: string;
    detailedReason?: string;
    cancelledTimestamp: Date;
};

export type SubscriptionRetryStatus = {
    nextScheduledRetryDate: Date;
    failedRetryOrderIds: string[];
};

export type PricePlan = {
    name?: string;
    amount: number;
    imageUrl?: string;
    paymentFrequencyInMonths: number;
    __typename?: "PricePlan";
};

export type PricePlans = PricePlan[];

export type SubscriptionProduct = {
    refProductId: string;
    amountDiscount?: number;
    percentageDiscount?: number;
    __typename: "SubscriptionProduct";
};

export type SubscriptionProducts = SubscriptionProduct[];

export type Subscription = {
    id: string;
    name: string;
    shopIds: string[];
    description: string;
    imageUrl: string;
    pricePlans: PricePlans;
    subscriptionProducts: SubscriptionProducts;
    companyId: string;
    sortOrder?: number | null;
    hidden: boolean;
    __typename?: "Subscription";
};

export type Subscriptions = Subscription[];

export type UserSubscriptionDTO = {
    id: string;
    creditCardInfo: CreditCardInfo;
    chosenPricePlan: PricePlan;
    subscription: Subscription;
    subscriptionId: string;
    subscriptionType: SubscriptionType;
    userAccountId: string;
    status: SubscriptionStatus;
    subscriptionRedemptionOrders: string[];
    upgradePaymentOrders: string[] | null;
    recurringPaymentOrders: string[];
    periodicityInMonths: number;
    preferredShopId: string;
    latestOrderTimestamp: string;
    latestRecurringPaymentTimestamp: string;
    subscriptionCancelReason: SubscriptionCancelReason;
    currentRetryStatus: SubscriptionRetryStatus;
    createdAt: string;
    __typename?: "UserSubscriptionDTO";
};

export type UserSubscriptionAndOrderDTO = {
    subscriptionPayments?: Order[];
    createdAt: string;
    deleted: Boolean;
    disabled: Boolean;
} & UserSubscriptionDTO;

export type UserSubscriptions = UserSubscriptionDTO[];

export type GroupSubscriptionRecipientContactInformation = {
    /**
     * Recipient company information
     */
    companyName: string;
    organisationNumber: string;
    addressLine: string;
    postCode: number | null;
    city: string;
    country: string;

    /**
     * Contact person information
     */
    contactName: string;
    contactEmail: string;
    contactPhoneNumber: string;
};

export type GroupSubscription = {
    id: string;
    name: string;
    buyerCompanyId: string;
    totalSeats: number;
    subscriptionId: string;
    recipientContactInformation: GroupSubscriptionRecipientContactInformation;
    description: string;
    pricePlan?: PricePlan;
    userSubscriptions: string[] | null;
    __typename?: "GroupSubscription";
};

export type GroupSubscriptions = GroupSubscription[];

export type GroupSubscriptionDTO = {
    subscription: Subscription;
    company: {
        name: string;
    };
} & GroupSubscription;

export type CompanySubscription = {
    company: {
        id: string;
        name: string;
        settings: {
            companySubscriptionSettings: CompanySubscriptionSettings;
        };
        countryLocale?: CountryLocale;
    };
    shops: {
        id: string;
        publicId: string;
        name: string;
    }[];
    subscription: Subscription;
};

export type CompanySubscriptions = CompanySubscription[];
