export type OpenAIRequest = {
    __typename: "OpenAIRequest";
    model: string;
    messages: {
        role: string;
        content: string;
    }[];
    n: number;
    temperature: number;
};

export type OpenAIResponse = {
    __typename: "OpenAIResponse";
    id: string;
    object: string;
    created: number;
    model: string;
    choices: {
        index: number;
        message: {
            role: string;
            content: string;
            created: number;
        };
        finish_reason: string;
    }[];
    usage: {
        prompt_tokens: number;
        completion_tokens: number;
        total_tokens: number;
    };
};

export enum OpenAILogType {
    SHOP_TAGS = "SHOP_TAGS",
    CONTENT_ASSISTANT = "CONTENT_ASSISTANT"
}

type KeyValue = {
    key: string;
    value: string;
};

export type OpenAILog = {
    __typename: "OpenAILog";
    id: string;
    request: OpenAIRequest;
    response: OpenAIResponse;
    extras: KeyValue[];
    promptModifications: KeyValue[];
    type: OpenAILogType;
    owner: string;
    createdAt: number;
};
