import { FallbackProps } from "react-error-boundary";
import { SelectedValues } from "Types";

export type ErrorMetaData = {
    company: string | null;
    shop: string | null;
    pos: string | null;
    terminal: string | null;
};

export interface ErrorBoundaryProps {
    children: React.ReactNode;
    metadata: ErrorMetaData;
    selectedValues?: SelectedValues;
    type?: string | null;
    sentryChild?: React.ComponentClass;
    fallbackComponent?: React.ComponentType<FallbackProps>;
}

export interface ErrorBoundaryState {
    hasError: boolean;
    error?: Error | null;
    componentStack?: string | null;
    type?: string | null;
}

export enum SENTRY_ERROR_TYPE {
    GENERAL = "GENERAL",
    PUBLIC = "PUBLIC",
    REDIRECT = "REDIRECT",
    RELOAD = "RELOAD"
}

export enum SENTRY_ERROR_KEY {
    TYPE = "type",
    METADATA = "metadata",
    COMPONENT = "component",
    FRONT_END_VERSION = "frontendVersion"
}
