// NOTE: TO BE EXPANDED AS AND WHEN

export enum GOOGLE_ANALYTICS_EVENT_CATEGORY {
    ONLINE_ORDER = "Online Order",
    ONLINE_CHECKOUT = "Online Checkout",
    ONLINE_ORDER_PAYMENT = "Online Order Payment",
    ONLINE_ORDER_STATUS = "Online Order Status",
    SUBSCRIPTION = "Subscription",
    USER_ACCOUNT = "User Account",
    GOOGLE_USER_ACCOUNT = "Google User Account",
    BACK_OFFICE = "Back Office",
    BO_GENERAL = "Back Office General",
    BO_SHORTCUTS = "Back Office Shortcuts",
    BO_CUSTOMER_FEEDBACK = "Back Office Customer Feedback",
    BO_COMPANY_SETTINGS = "Back Office Company Settings",
    BO_SHOP_SETTINGS = "Back Office Shop Settings",
    BO_HELP = "Back Office Help",
    BO_ARTICLES = "Back Office Articles",
    BO_MANUAL = "Back Office Manual",
    BO_PRODUCT_LIBRARY = "Back Office Product Library",
    BO_ALCOHOL_TAG_VIEW = "Back Office Alcohol Tag View",
    BO_MENUS = "Back Office Menus",
    BO_CASH_REGISTER = "Back Office Cash Register",
    BO_LOGIN = "Back Ofice Login",
    BO_CATERING = "Back Office Catering",
    BO_ORDER_HISTORY = "Back Office Order History",
    BO_REPORTS = "Back Office Reports",
    BO_INVOICES = "Back Office Invoices",
    BO_DISCOUNTS = "Back Office Discounts",
    BO_SUBSCRIPTIONS = "Back Office Subscriptions",
    EXPRESS_ORDER = "Express Order",
    POS = "POS",
    POS_FOOTER = "Pos Footer",
    POS_CHECKOUT = "Pos Checkout"
}

export enum GOOGLE_ANALYTICS_EVENT_ACTION {
    ADD_TO_CART = "Add To Cart",
    ADD_UPSELL = "Add Upsell",
    ADD_ORDER = "Add Order",
    ADD_LAST_ORDER = "Add Last Order",
    SWISH_PAYMENT = "Swish Payment",
    CARD_PAYMENT = "Card Payment",
    TIP_ADDED = "Tip Added",
    CLICK = "Click",
    CLICK_BUTTON = "Click Button",
    CLICK_LINK = "Click Link",
    DELETE = "Delete",
    DISCOUNT_CODE_USED = "Discount Code Used",
    PARTNER_BENEFITS_CLICK = "Partner Benefits",
    INPUT = "Input",
    SELECT = "Select",
    SCROLL = "Scroll",
    NAVIGATE = "Navigate",
    HOVER = "Hover",
    LOGIN = "Login",
    OPEN_MODAL = "Open Modal",
    OPEN_DRAWER = "Open Drawer",
    REGISTER = "Register",
    SUCCESSFUL_PAYMENT = "Successful Payment",
    FAILED_PAYMENT = "Failed Payment",
    SELECTED = "Selected",
    CANCELLED = "Cancelled",
    RESUMED = "Resumed",
    UPGRADE = "Upgrade",
    MERGE = "Merge",
    POPUP = "Popup"
}

/**
 * Non interaction = true / this is our code that would do this e.g page load
 */
export type GoogleAnalyticsType = {
    category: GOOGLE_ANALYTICS_EVENT_CATEGORY;
    action: GOOGLE_ANALYTICS_EVENT_ACTION;
    label?: string; // additional information
    nonInteraction?: boolean; // true if not a user action
    transport?: "beacon" | "xhr" | "image";
};
