import { StringOrNull, MenuProduct, MenuBundleProduct } from "./";

export enum ComboDiscountType {
    STATIC_PRICE = "STATIC_PRICE"
}

export type ComboCategory = {
    limit: number;
    maxPerPurchase?: number;
    menuProductIds: string[];
};

export type ComboDiscount = {
    id?: string;
    companyId: string;
    shopIds: string[];
    name: string;
    description: string;
    comboCategories: ComboCategory[];
    type: ComboDiscountType;
    staticPrice: number;
    startDate: string;
    endDate: string;
    disabled: boolean;
    canCombine?: boolean;
    wasApplied?: number;
    productNames?: string[];
    upsellMenuProducts?: (MenuProduct | MenuBundleProduct)[];
};

export type DiscountCheck = {
    id: string;
    discountValue: number;
    quantityPerDiscount: number;
    discountApplied: number;
    quantityLeft: number;
    cartIdsNotUsed: string[];
    canUseMultipleDiscounts?: boolean;
    cartIdsUsed?: Map<string, number>;
};

export type SelectedCartProduct = {
    cartId: string;
    orgUnitPrice: number;
    currentQuantity: number;
    originalQuantity?: number;
    discountsUsedAndCanCombine?: Map<string, boolean>;
    name: string;
    productId?: StringOrNull;
};

export type PotentialUpsellDiscounts = {
    cartIds: string[];
    quanityLeftToDiscount: number;
    discountId: string;
};

export type UpsellDiscount = {
    cartId: string;
    productName: string;
    discounts?: PotentialComboDiscount[];
    productId: string;
    quantity: number;
    potentialApplied?: Map<string, number>;
};

export type DiscountApplied = {
    discountId: string;
    cartIds: Map<string, number>;
};

export type SelectedCartProducts = {
    canApply: number;
    selectedCartProducts?: SelectedCartProduct[];
};

export type PotentialComboDiscount = ComboDiscount & SelectedCartProducts;
