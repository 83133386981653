/*
Tip: Handy when you want to override a default event.

Example:
    Check out Popup.tsx and see how its being used
*/

export const wrapEvent = (ourHandler: Function, theirHandler?: Function) => (event: any) => {
    if (theirHandler) {
        theirHandler(event);
    }

    if (!event.defaultPrevented) {
        return ourHandler(event);
    }
};
