import { ServiceFee, CombinedDiscount, TypeOfServiceDisruption } from ".";
// very basic order

import { OrderProduct, ContactInformation, SubscriptionMeta, EatingOption, UserInformation } from "./";
import {
    PaymentMethod,
    ReceiptType,
    ThirdPartyDeliveryType,
    KitchenOrderStatus,
    batchStatusConstants,
    ShopTableStatus
} from "Constants";
import { Maybe, PaymentStatus } from "../generated/graphql";

export type TableMeta = {
    id: string;
    name: string;
    shopId: string;
};
export interface Order {
    id: string;
    invoiceData: InvoiceData;
    orderProducts: OrderProduct[];
    totalAmount: number;
    paymentMethodAmounts: PaymentMethodAmount[];
    deliveryFee: number;
    serviceFee: ServiceFee;
    totalNetAmount: number;
    totalAmountUpsell: number;
    driveThrough: boolean;
    postponePayment: boolean;
    shopId: string;
    shopName: string;
    organisationNumber: string;
    contactInformation: ContactInformation;
    receiptNumber: number;
    customerId: string;
    purchaseDate: Date;
    vatRatesAndAmounts: VatRateAmount[];
    orderNo: number;
    webPaymentId: string;
    pickupTime: Date;
    timeInterval: string;
    comment: string;
    comment2: string;
    orderType: OrderType;
    webOrderType: WebOrderType;
    posId: string;
    terminalId: string;
    receiptType: ReceiptType;
    referenceOrderId: string;
    discount: boolean;
    discountMeta: DiscountMeta;
    subscriptionMeta: SubscriptionMeta;
    posName: string;
    takeAway: boolean;
    homeDelivery: boolean;
    eatingOption: EatingOption;
    thirdPartyDelivery: ThirdPartyDeliveryType;
    orderControlUnitValues: OrderControlUnitValues;
    discountIds: string[];
    hasCopy: boolean;
    paymentStatus: PaymentStatus;
    refundStatus: RefundStatus;
    failedReason: string;
    onlineOrderStatus: OnlineOrderStatus;
    swishResponses?: SwishResponse[];
    userInformation: UserInformation;
    creditCardInfo: CreditCardInfo;
    onlineOrderStatusTimestamps: StatusTimestamp[];
    puckNo: string;
    deliveryCreateResponse: DeliveryCreateResponse;
    tableMeta: TableMeta;
    smsResponses: any[]; // TODO add LinkSendSmsResponse from mothership
    cateringConfirmationDate: Date;
    thirdPartyJSON: string;
    thirdPartyCode: string;
    acceptsMarketing: boolean;
    qr: boolean;
    userAccountId: string;
    clientInformation: any; // TODO add clientInformation from mothership
    autoAccepted: boolean;
    scheduledOrder: boolean;
    tip: number;
    referenceReceiptNumber: number;
    anonymousUserId?: string;
}

export interface SwishResponse {
    id?: string;
    payeePaymentReference?: string;
    payerPaymentReference?: string;
    originalPaymentReference?: string;
    paymentReference?: string;
    payerAlias?: string;
    payeeAlias?: string;
    callbackUrl?: string;
    amount?: string;
    message?: string;
    status?: SwishStatus;
    dateCreated?: string;
    datePaid?: string;
    errorCode?: string;
    errorMessage?: string;
    additionalInformation?: string;
    paymentRequestToken?: string;
    qrFormat?: string;
    currency?: string;
}

export enum SwishStatus {
    VALIDATED = "VALIDATED",
    ERROR = "ERROR",
    DEBITED = "DEBITED",
    PAID = "PAID",
    DECLINED = "DECLINED",
    CANCELLED = "CANCELLED"
}
export interface KitchenOrder {
    id: string;
    online: boolean;
    driveThrough: boolean;
    postponePayment: boolean;
    kitchenOrderProducts: OrderProduct[];
    orderNo: number;
    status: KitchenOrderStatus;
    pickupTime: Date;
    orderType: OrderType;
    shopId: string;
    comment: string;
    contactInformation: ContactInformation;
    takeAway: boolean;
    homeDelivery: boolean;
    eatingOption: EatingOption;
    orderId: string;
    paymentStatus: PaymentStatus;
    orderStatusTimestamps: any[];
    prepTime: number;
    scheduledOrder: boolean;
    onlineOrderStatus: OnlineOrderStatus;
    webOrderType: WebOrderType;
    thirdPartyCode?: string;
    updatedAt?: string;
}

type BatchStatusKeys = keyof typeof batchStatusConstants;
type BatchStatusValues = (typeof batchStatusConstants)[BatchStatusKeys];

type KitchenBatchOrder = {
    kitchenOrderId: string;
    orderStatus: BatchStatusValues;
};

type PickupBatch = {
    batchNo: number;
    batchStatus: BatchStatusValues;
    createdAt: string;
    id: string;
    kitchenBatchOrderList: KitchenBatchOrder[];
};

export type PickupBatches = PickupBatch[];

export type DeliveryCreateResponse = {
    arriveAtCustomerTime: Date;
    courierPickupTime: Date;
    httpStatus: string;
    error: boolean;
    message: string;
    delivered: string;
};

export type StatusTimestamp = {
    onlineOrderStatus: OnlineOrderStatus;
    timestamp: Date | string;
    status?: KitchenOrderStatus;
    shopTableStatus?: ShopTableStatus;
};

// We currently have a type and an object of this in constants.ts
//  we will remove those and only use this enum
export enum OnlineOrderStatus {
    PENDING = "PENDING",
    CONFIRMED = "CONFIRMED",
    DENIED = "DENIED",
    INVALID = "INVALID",
    ERROR_DELIVERY_CREATE = "ERROR_DELIVERY_CREATE",
    ERROR_DELIVERY_CANCEL = "ERROR_DELIVERY_CANCEL",
    SHOP_AUTO_DENIED = "SHOP_AUTO_DENIED",
    CLIENT_AUTO_DENIED = "CLIENT_AUTO_DENIED",
    OFFLINE_AUTO_DENIED = "OFFLINE_AUTO_DENIED",
    DECLINED = "DECLINED",
    FAILED_PAYMENT = "FAILED_PAYMENT",
    CANCELLED_PAYMENT = "CANCELLED_PAYMENT",
    CANCELLED = "CANCELLED",
    PRE_CONFIRMED = "PRE_CONFIRMED"
}

export enum RefundStatus {
    Failed = "FAILED",
    FullyRefunded = "FULLY_REFUNDED",
    PartiallyRefunded = "PARTIALLY_REFUNDED",
    Pending = "PENDING"
}

export type OrderControlUnitValues = {
    controlUnitNumber: string;
    controlUntResponseHash: string;
    failedRequest: boolean;
    responseCode: number;
    responseText: string;
};

export interface InvoiceOrder extends Order {
    invoiceData: InvoiceData;
}

export type PaymentMethodAmount = {
    paymentMethod: PaymentMethod;
    amount: number;
};

export type VatRateAmount = {
    amount: number;
    vatRate: number;
};

export type DiscountMeta = {
    id?: string;
    discounts?: CombinedDiscount[];
    name: string;
    code?: string;
    amount?: number;
    rate?: number;
    originalPrice: number;
    totalDiscountValue: number;
    campaignId?: string; 
};

export type InvoiceData = {
    invoiceAddress: InvoiceAddress;
    organisationNumber: string;
    invoiceReference: string;
    contactName: string;
    isHandled: boolean;
    customerNumber: string; 
};

type InvoiceAddress = {
    name: string;
    addressLine: string;
    city: string;
    postCode: string;
};

export enum WebOrderType {
    BASIC = "BASIC",
    EXPRESS = "EXPRESS",
    CATERING_TAKE_AWAY = "CATERING_TAKE_AWAY",
    CATERING_DELIVERY = "CATERING_DELIVERY",
    HOME_DELIVERY = "HOME_DELIVERY",
    GIFT_CARD = "GIFT_CARD",
    GROUP_ORDER = "GROUP_ORDER"
}

export enum OrderType {
    WEB = "WEB",
    POS = "POS",
    SUBSCRIPTION = "SUBSCRIPTION",
    GIFT_CARD = "GIFT_CARD",
    FOODORA = "FOODORA",
    UBEREATS = "UBEREATS",
    WOLT = "WOLT"
}

export type CreditCardStatusTimestamp = {
    timestamp: string;
    netAxeptStatus: string;
};

export enum InvoiceCardProductType {
    DEBIT = "DEBIT",
    CREDIT = "CREDIT",
    CORPORATE = "CORPORATE",
    NON_EU = "NON_EU",
    AMEX = "AMEX",
    EDENRED_LUNCH = "EDENRED_LUNCH"
}

export enum FailedOrderReason {
    OUT_OF_STOCK = "OUT_OF_STOCK"
}

export type CreditCardInfo = {
    cardIssuer?: string;
    cardNumber?: string;
    transactionId?: string;
    countryName?: string;
    cardType?: string;
    cardProductType?: string;
    countryCode?: number;
    maskedPAN?: string;
    PANHash?: string;
    paymentTimestamps?: CreditCardStatusTimestamp[];
    invoiceCardType?: InvoiceCardProductType;
};

export type CalculateServiceFee = {
    calculateServiceFee: ServiceFee;
};

export type WebPaymentResponse = {
    errorText: Maybe<string>;
    operationSuccess: boolean;
    panHash: Maybe<string>;
    qrCode: Maybe<string>;
    redirectUrl: Maybe<string>;
    token: Maybe<string>;
    transactionId: Maybe<string>;
};

export type ServiceDisruption = {
    id?: string;
    typeOfServiceDisruption: TypeOfServiceDisruption;
    serviceDisrupted: boolean;
    setOnAllShops: boolean;
};

export type TipsValues = 0 | 5 | 10 | 15 | 20;
