import { flatMap, groupBy, maxBy, minBy } from "lodash";
import moment from "moment";

import { ActiveHour, EatingOption, EatingPreference, Menu, PICKUP_EATING_OPTIONS } from "Types";
import { getMenusByEatingOption } from "Utils";
import { CustomerPickupFormik } from "../customer/pages/onlineOrder/components/OnlineOrderPickupModal/OnlineOrderPickupModal";

const timeToNumber = (time: string) => Number(time.replace(":", ""));

const findEarliestActiveHour = (activeHours: ActiveHour[], key: "startingHour" | "stoppingHour") => {
    return minBy(activeHours, activeHour => timeToNumber(activeHour[key]));
};

const findLatestActiveHour = (activeHours: ActiveHour[], key: "startingHour" | "stoppingHour") => {
    return maxBy(activeHours, activeHour => timeToNumber(activeHour[key]));
};

const findEarliestAndLatestActiveHour = (activeHours: ActiveHour[]) => {
    const earliest = findEarliestActiveHour(activeHours, "startingHour");
    const latest = findLatestActiveHour(activeHours, "stoppingHour");

    return {
        startingHour: earliest?.startingHour,
        stoppingHour: latest?.stoppingHour
    };
};

const allMenusActiveHours = (menus: Menu[]) => flatMap(menus, menu => menu.activeHours);

const getActiveHoursByWeekName = (activeHours: ActiveHour[], dayOfWeek: string) => {
    return activeHours.filter(activeHour => activeHour.dayOfWeek === dayOfWeek);
};

const getMergedActiveHoursByEatingOption = (menus: Menu[], eatingOption: EatingOption): ActiveHour[] => {
    const _menus = menus.filter(menu => menu.eatingOptions.includes(eatingOption));
    const _menusActiveHours = flatMap(_menus, menu => menu.activeHours);

    const grouped = groupBy(_menusActiveHours, "dayOfWeek");

    return Object.entries(grouped).map(([key, value]) => {
        const { startingHour, stoppingHour } = findEarliestAndLatestActiveHour(value);

        return {
            dayOfWeek: key,
            startingHour: startingHour || "",
            stoppingHour: stoppingHour || ""
        };
    });
};

const buildInitialActiveHours = (days: string[]) => {
    const weekDaysFromMonday = [...days];
    weekDaysFromMonday.push(weekDaysFromMonday.shift()!);
    return weekDaysFromMonday.map((day: string) => ({
        dayOfWeek: day,
        startingHour: "",
        stoppingHour: ""
    }));
};

const shouldDisplayClosed = (
    startingHour: string | undefined,
    stoppingHour: string | undefined,
    dateStr: string,
    backendDiff: number
) => {
    let displayClosed = !startingHour || !stoppingHour;

    if (startingHour && stoppingHour) {
        const startTime = timeToNumber(startingHour);
        const stoppingTime = timeToNumber(stoppingHour);

        // If backend time diff with 10 mins, we add the backend diff, otherwise we don't add it.
        // This controls only if a text will be displayed, meaning, not too big of a deal.
        const _backendDiff = Math.abs(backendDiff) > 600000 ? backendDiff : 0;
        const backendHour = moment(dateStr, "YYYY-MM-DD HH:mm").add(_backendDiff, "ms").format("HH:mm");
        const backendTime = timeToNumber(backendHour);

        //need to consider if shop is open after midnight
        if (startTime > stoppingTime) {
            displayClosed = !(backendTime >= startTime || backendTime <= stoppingTime);
        } else {
            displayClosed = !(backendTime >= startTime && backendTime <= stoppingTime);
        }
    }
    return displayClosed;
};

const getDisplayClosed = (
    option: PICKUP_EATING_OPTIONS,
    pickupFormValues: CustomerPickupFormik,
    menus: Menu[],
    backendDiff: number,
    onlineActiveHours: ActiveHour[] | []
) => {
    let startingHour;
    let stoppingHour;
    let displayClosed = false;

    const isHomeDelivery = option === EatingPreference.HOME_DELIVERY;

    const subtractDays = pickupFormValues.isAfterMidnight ? 1 : 0;
    const pickupDateWeekDay = moment(pickupFormValues.pickupDate, "YYYY-MM-DD")
        .subtract(subtractDays, "d")
        .locale("en")
        .format("dddd")
        .toUpperCase();

    if (isHomeDelivery) {
        const _menus = getMenusByEatingOption(menus, option as any);
        const activeHours = getActiveHoursByWeekName(allMenusActiveHours(_menus), pickupDateWeekDay);
        const _activeHour = findEarliestAndLatestActiveHour(activeHours);

        startingHour = _activeHour.startingHour || "";
        stoppingHour = _activeHour.stoppingHour || "";
    } else {
        //for eat here and take away options
        const activeHoursList = getActiveHoursByWeekName(onlineActiveHours, pickupDateWeekDay);
        //getActiveHoursByWeekName returns array from filter
        const activeHours = activeHoursList[0];

        startingHour = activeHours ? activeHours.startingHour : "";
        stoppingHour = activeHours ? activeHours.stoppingHour : "";
    }

    if (pickupFormValues.pickupTime) {
        const timeStr =
            pickupFormValues.pickupTime === "asap"
                ? moment().add(backendDiff, "ms").format("HH:mm")
                : pickupFormValues.pickupTime;
        const dateStr = `${pickupFormValues.pickupDate} ${timeStr}`;
        displayClosed = shouldDisplayClosed(startingHour, stoppingHour, dateStr, backendDiff);
    }

    return [startingHour, stoppingHour, displayClosed];
};

export {
    buildInitialActiveHours,
    findEarliestActiveHour,
    findLatestActiveHour,
    findEarliestAndLatestActiveHour,
    allMenusActiveHours,
    getActiveHoursByWeekName,
    timeToNumber,
    getMergedActiveHoursByEatingOption,
    shouldDisplayClosed,
    getDisplayClosed
};
