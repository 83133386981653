// Will merge multiple query data
export const formatQueryDataResponse = (data: any) => {
    return data.reduce((acc: any, curr: any) => {
        if (!!curr) {
            const [first] = Object.entries(curr);
            if (first !== null) {
                acc[first[0]] = first[1];
            }
        }

        return acc;
    }, {});
};
