import Color from "color";

import { HEX_REGEX, Opacity, RGBA_REGEX } from "Types";
import { expressColors } from "../theme/expressColors";

export const isDarkColor = (color: string) => Color(color).isDark();

export const string2Hex = (str: string) => {
    let hash = 0;
    if (str.length === 0) return hash;
    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
        hash = hash & hash;
    }
    let color = "#";
    for (let j = 0; j < 3; j++) {
        let value = (hash >> (j * 8)) & 255;
        color += ("00" + value.toString(16)).substr(-2);
    }
    return color;
};

export const convertHEXToRGBA = (hex: string, opacity?: number) => {
    hex = hex.replace("#", "");
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    return `rgba(${r}, ${g}, ${b}, ${opacity == undefined ? 1 : opacity})`;
};

/**
 * [FUNCTION] - Change the opacity of RGBA
 * @param rgba
 * @param newOpacity
 * @returns
 */
export const changeRGBAOpacity = (rgba: string, newOpacity: Opacity) => {
    const rgbaMatch = rgba.match(RGBA_REGEX);

    if (rgbaMatch) {
        const red = rgbaMatch[1];
        const green = rgbaMatch[2];
        const blue = rgbaMatch[3];
        const alpha = newOpacity;
        return `rgba(${red}, ${green}, ${blue}, ${alpha})`;
    } else {
        throw new Error("Invalid RGBA colour string");
    }
};

export const getDarkenedVersionOfColour = (hexColor: string, percent: number = 20) => {
    if (!HEX_REGEX.test(hexColor)) {
        throw new Error(`Invalid hex colour code: ${hexColor}`);
    }

    // Remove the hash symbol if present
    let removeHex = hexColor.replace(/^#/, "");

    // If the hex code is 3 characters, convert to 6 characters
    if (removeHex.length === 3) {
        removeHex = removeHex
            .split("")
            .map(char => char + char)
            .join("");
    }

    // Convert hex to RGB
    let r = parseInt(removeHex.slice(0, 2), 16);
    let g = parseInt(removeHex.slice(2, 4), 16);
    let b = parseInt(removeHex.slice(4, 6), 16);

    // Calculate the darkened RGB values
    r = Math.max(0, r - Math.floor((percent / 100) * r));
    g = Math.max(0, g - Math.floor((percent / 100) * g));
    b = Math.max(0, b - Math.floor((percent / 100) * b));

    // Convert the RGB values back to hex
    const darkenedHex =
        "#" + r.toString(16).padStart(2, "0") + g.toString(16).padStart(2, "0") + b.toString(16).padStart(2, "0");

    return darkenedHex;
};
export const getLightenedVersionOfColor = (hexColor: string, percent: number = 20) => {
    if (!HEX_REGEX.test(hexColor)) {
        throw new Error(`Invalid hex colour code: ${hexColor}`);
    }

    // Remove the hash symbol if present
    let removeHex = hexColor.replace(/^#/, "");

    // If the hex code is 3 characters, convert to 6 characters
    if (removeHex.length === 3) {
        removeHex = removeHex
            .split("")
            .map(char => char + char)
            .join("");
    }

    // Convert hex to RGB
    let r = parseInt(removeHex.slice(0, 2), 16);
    let g = parseInt(removeHex.slice(2, 4), 16);
    let b = parseInt(removeHex.slice(4, 6), 16);

    // Calculate the lightened RGB values
    r = Math.min(255, r + Math.floor((percent / 100) * (255 - r)));
    g = Math.min(255, g + Math.floor((percent / 100) * (255 - g)));
    b = Math.min(255, b + Math.floor((percent / 100) * (255 - b)));

    // Convert the RGB values back to hex
    const lightenedHex =
        "#" + r.toString(16).padStart(2, "0") + g.toString(16).padStart(2, "0") + b.toString(16).padStart(2, "0");

    return lightenedHex;
};

export const getTextColor = (color: string) => {
    const themeColor = !!expressColors[color] ? expressColors[color] : color;
    const isDark = isDarkColor(themeColor as string);
    return isDark ? "white" : "expressTextColor";
};

export const getExpressSecondaryDarkColor = (secondaryLightColor: string) => {
    return secondaryLightColor !== expressColors.expressSecondary
        ? getDarkenedVersionOfColour(secondaryLightColor, 10)
        : expressColors.expressSecondaryDark;
};
