import { ThirdPartyDeliveryType } from "Constants";

export enum SYNC_OPERATION {
    MENUSYNC = "MENUSYNC",
    OUTOFSTOCK = "OUTOFSTOCK"
}

export enum LOG_STATUS {
    SUCCESS = "SUCCESS",
    FAILED = "FAILED",
    IN_PROGRESS = "IN_PROGRESS"
}

export type Request = {
    url: string;
    method: string;
    data: string;
};

export type Response = {
    status: string;
    message: string;
};

export type MenuSyncLog = {
    id: string;
    createdAt: Date;
    referenceId: string[];
    shopId: string;
    companyId: string;
    syncOperation: SYNC_OPERATION;
    thirdPartyDeliveryType: ThirdPartyDeliveryType;
    request: Request;
    response: Response;
    status: LOG_STATUS;
};
