import React from "react";
import { toast, ToastOptions } from "react-toastify";

import { Flex, Text } from "Atoms";

/**
 * [NOTIFICATION] - show toast error notification
 * @param errorMessage
 * @param toastOptions
 */
export const showToastError = (errorMessage: string, toastOptions?: ToastOptions) => {
    toast.error(
        <Flex textAlign="center" flexDirection="column">
            <Text>{errorMessage}</Text>
        </Flex>,
        {
            draggable: false,
            autoClose: 2000,
            ...toastOptions
        }
    );
};

/**
 * [NOTIFICATION] - show toast success notification
 * @param messageText
 * @param toastOptions
 */
export const showToastSuccess = (messageText: string, toastOptions?: ToastOptions) => {
    toast.success(
        <Flex textAlign="center" flexDirection="column">
            <Text>{messageText}</Text>
        </Flex>,
        {
            draggable: false,
            autoClose: 2000,
            ...toastOptions
        }
    );
};

/**
 * [NOTIFICATION] - show toast warning notification
 * @param messageText
 * @param toastOptions
 */
export const showToastWarning = (messageText: string, toastOptions?: ToastOptions) => {
    toast.warn(
        <Flex textAlign="center" flexDirection="column">
            <Text>{messageText}</Text>
        </Flex>,
        {
            draggable: false,
            autoClose: 2000,
            ...toastOptions
        }
    );
};
