/*
Tip: Handy when your ref is either a function or a standard value

*/

export const setRef = (ref: React.ForwardedRef<any>, value: any) => {
    if (typeof ref === "function") {
        ref(value);
    } else if (ref) {
        ref.current = value;
    }
};

/*
Tip: Useful when you have multiple function to invoke at once

Example:
    const a = (num) => console.log(num);
    const b = (num) => console.log(num);

    createChainedFunction(a(2), b(5)) // Will output 2 and 5
*/
export function createChainedFunction(...funcs: any[]) {
    return funcs.reduce(
        (acc, func) => {
            if (func == null) {
                return acc;
            }
            return function chainedFunction(this: Function, ...args: any) {
                acc.apply(this, args);
                func.apply(this, args);
            };
        },
        () => {}
    );
}

/*
Tip:
    Can be used to check values in objects

Example:
    const object = {
        isHappy: true
    }

    const isTrue = (val) => !!val;
    const isHappy = withKey("isHappy")(isTrue)(object) // true

Since its a curried function, we can also invoke it later if we want:

    Example:
        const withKeyIsHappy = withKey("isHappy"); // function
        const isHappy = withKeyIsHappy(isTrue)(object) // true

We can even do this:
    Example:
        const withKeyIsHappy = withKey("isHappy"); // function
        const checkAfterHappiness= withKeyIsHappy(isTrue) // function
        const isHappy = checkAfterHappiness(object) // true

Doing something like, checkAfterHappiness, allows us to re-use function in a more
FP (functional programming) way.

*/
export const withKey =
    (key: string) =>
    (fn: Function) =>
    ({ [key]: value }: Record<string, any>) =>
        fn(value);

/*
Tip:
    Just like and function below, but it only takes in one function
Example:
    const isTrue = () => true;

    const conditions = is(isTrue)(); // true

    Since and is a curried function, we can also invoke it later if we want:
        const checkConditions = is(isTrue);

        checkConditions() // true
*/
export const is = (fn: Function) => (obj: Object) => {
    if (!fn(obj)) {
        return false;
    }

    return true;
};

/*
Tip:
    Useful when multiple function should return true
    A functional approach of .every

Example:
    const isTrue = () => true;
    const isFalse = () => false;

    const conditions = and(isTrue, isFalse)(); // false

    Since and is a curried function, we can also invoke it later if we want:
        const checkConditions = and(isTrue, isFalse);

        checkConditions() // false
*/
export const and =
    (...fns: Function[]) =>
    (obj: Object) => {
        for (const fn of fns) {
            if (!fn(obj)) return false;
        }
        return true;
    };

/*
Tip:
    Useful when at least one function should return true
    A functional approach of .some

Example:
    const isTrue = () => true;
    const isFalse = () => false;

    const conditions = and(isTrue, isFalse)(); // true

    Since or is a curried function, we can also invoke it later if we want:
        const checkConditions = and(isTrue, isFalse);

        checkConditions() // true
*/
export const or =
    (...fns: Function[]) =>
    (obj: any) => {
        for (const fn of fns) {
            if (fn(obj)) return true;
        }
        return false;
    };

/**
 * Checks if the provided value is a function.
 * @template T - The expected function type.
 * @param value - The value to be checked.
 * @returns True if the value is a function, otherwise false.
 */
export const isFunction = <T extends Function = Function>(value: any): value is T => typeof value === "function";

/**
 * Executes a function or returns a value based on the input.
 * @template T - The expected return type.
 * @template U - The function argument types.
 * @param valueOrFn - The value or function to be executed.
 * @param args - The arguments to pass to the function if valueOrFn is a function.
 * @returns The result of the function or the input value if not a function.
 */
export const runIfFn = <T, U>(valueOrFn: T | ((...fnArgs: U[]) => T), ...args: U[]): T =>
    isFunction(valueOrFn) ? valueOrFn(...args) : valueOrFn;

/**
 * Type alias for a React ref, which can be a RefCallback or a MutableRefObject.
 * @template T - The type of the ref.
 */
export type ReactRef<T> = React.RefCallback<T> | React.MutableRefObject<T>;

/**
 * Assigns a value to a React ref.
 * @template T - The type of the ref.
 * @param ref - The ref to assign the value to.
 * @param value - The value to assign to the ref.
 */
export const assignRef = <T = any>(ref: ReactRef<T> | null | undefined, value: T) => {
    if (ref == null) return;

    if (typeof ref === "function") {
        ref(value);
        return;
    }

    try {
        ref.current = value;
    } catch (error) {
        throw new Error(`Cannot assign value '${value}' to ref '${ref}'`);
    }
};

/**
 * Merges multiple React refs into a single callback ref.
 * @template T - The type of the refs.
 * @param refs - The array of refs to be merged.
 * @returns A callback ref that updates all input refs.
 */
export const mergeRefs = <T>(...refs: (ReactRef<T> | null | undefined)[]) => {
    return (node: T | null) => {
        refs.forEach(ref => {
            assignRef(ref, node);
        });
    };
};

/**
 * Type alias for inferring the arguments of a function.
 * @template T - The function type.
 */
type Args<T extends Function> = T extends (...args: infer R) => any ? R : never;

/**
 * Combines multiple event handlers into a single handler.
 * @template T - The event handler type.
 * @param fns - The array of event handlers.
 * @returns A single event handler that calls each input event handler in order.
 */
export const callAllHandlers = <T extends (event: any) => void>(...fns: (T | undefined)[]) => {
    return function func(event: Args<T>[0]) {
        fns.some(fn => {
            fn?.(event);
            return event?.defaultPrevented;
        });
    };
};

/**
 * Pauses execution of an async function for a specified duration.
 *
 * @param {number} duration - The amount of time to pause execution, in milliseconds.
 * @returns {Promise} A Promise that resolves after the specified duration.
 */
export const sleep = (duration: number) => new Promise(resolve => setTimeout(resolve, duration));
