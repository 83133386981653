/**
 * Type of cookies present
 */
export enum COOKIE_CONSENT_TYPES {
    ESSENTIAL = "essential",
    FUNCTIONAL = "functional",
    ANALYTICS = "analytics",
    MARKETING = "marketing"
}

/**
 * Custom cookie conset
 * e.g {"essential": true, functional: true. analytics: true, marketing: true}
 */
export type CookieCustomerConsent = {
    [key in COOKIE_CONSENT_TYPES]: boolean;
};

/**
 * Cookies keys that will be used
 */
export enum CookieKeys {
    ONLINE_SAVE_FORM_VALS = "formValues",
    ONLINE_SAVE_ME = "saveMe",
    SELECTED_POS = "selectedPos",
    JWT_REFRESH_TOKEN = "qJwtRefreshToken",
    JWT_ACCESS_TOKEN = "qJwtAccessToken",
    ONLINE_COOKIE_CONSENT = "cookie_consent",
    PREVENT_WEB_CARD_PROCESS = "prevent_web_card_process"
}

/**
 * Type for matching each cookie key to a consent type
 */
export type CookiesConsentType = {
    [key in CookieKeys]: COOKIE_CONSENT_TYPES;
};

/**
 * Matching cookie key to consent type
 */
export const CookieKeyToConsentType: Partial<CookiesConsentType> = {
    formValues: COOKIE_CONSENT_TYPES.FUNCTIONAL,
    saveMe: COOKIE_CONSENT_TYPES.MARKETING,
    selectedPos: COOKIE_CONSENT_TYPES.ESSENTIAL,
    qJwtAccessToken: COOKIE_CONSENT_TYPES.ESSENTIAL,
    qJwtRefreshToken: COOKIE_CONSENT_TYPES.ESSENTIAL
};
