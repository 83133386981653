import { isObject } from "Utils";

/**
 * @example const default: InitialDefaultValues<Discount> { name: "", code: "", ...rest }
 */
export type InitialDefaultValues<T> = {
    [P in keyof T]: T[P] extends null | undefined ? T[P] : T[P];
};

/**
 * [FUNCTION] pass in the default values with actual values of the object
 * @param obj
 * @param defaultValues
 * @param removeNulls - only removes from the obj props which is from the db! not defaults
 * @example
 * const defaultValues: InitialDefaultValues<Discount> { name: "", code: "", ...rest }
 * const initialValues = setInitialFormValues<Discount>(defaultValues, discount);
 * // discount can be empty or from the db - if values are not in discount it will use default values
 */
export const setInitialFormValues = <T>(
    defaultValues: InitialDefaultValues<T>,
    obj?: T,
    removeNulls: boolean = true
): T => {
    if (removeNulls) {
        return { ...defaultValues, ...removeNullsInObject<T>(obj) };
    }
    return { ...defaultValues, ...obj };
};

/**
 * [FUNCTION] provide empty array by type
 * @description help with type script errors when setting an empty array
 * @returns
 * @example { discounts: emptyArrayType<Discount>()  }
 */
export const emptyArrayType = <T>() => {
    return [] as T[];
};

/**
 * [FUNCTION] remove nulls from object mainly used from database - only simple objects
 *  * [TEST_SUITE] * src/__tests__/formHelpers/formHelpers.test.ts
 *  * Simple objects only (no nested)
 * @param obj
 * @returns
 * @example removeNullsInObject<Discount>(obj);
 */
export const removeNullsInObject = <T>(obj?: T) => {
    if (!obj || !isObject(obj)) {
        return obj;
    }

    return (
        !!obj &&
        (Object.entries(obj)
            .filter(([_, value]) => value !== null)
            .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {}) as T)
    );
};
