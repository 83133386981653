import { CartProduct, OrderWindow } from "Types";

/**
 * [FUNCTION] find cart product by cart product id
 * @param {OrderWindow} orderWindow
 * @param {string} cartProductId
 * @returns {CartProduct} return cart product
 */
export const findCartProduct = (orderWindow: OrderWindow | null | undefined, cartProductId: string) => {
    return orderWindow?.cartProducts?.find(cartProduct => cartProduct.id === cartProductId);
};

/**
 * [FUNCTION] Check if cart contains at least one bundle product
 * @param {CartProduct[]} cartProducts
 * @returns {boolean} true or false
 */
export const cartContainsAtLeastOneBundleProduct = (cartProducts: CartProduct[]) => {
    return !!cartProducts.some(cartProduct => !!cartProduct.menuBundleProduct);
};

/**
 * [FUNCTION] Check if selected bundle id is in order window
 * @param {CartProduct} bundleCartProduct
 * @param {OrderWindow[] }orderWindows
 * @param {string} selectedOrderWindowId
 * @returns
 */
export const selectedBundleIdIsInOrderWindow = (
    bundleCartProduct: CartProduct | null,
    orderWindows: OrderWindow[],
    selectedOrderWindowId: string | null
) => {
    const orderWindow = orderWindows.find(window => window.id === selectedOrderWindowId);
    const orderWindowIndex =
        orderWindow?.cartProducts.findIndex(cartProduct => cartProduct.id === bundleCartProduct?.id) ?? -1;
    return orderWindowIndex > -1;
};
