import { PosTypes } from "Constants";

export type MenuCategoryProducts = {
    menuId: string;
    categoryIds: string[];
    productIds: string[];
};

export enum APPLIED_DISCOUNT_TYPE {
    PER_PRODUCT = "PER_PRODUCT",
    TOTAL = "TOTAL"
}

export type Discount = {
    id: string;
    amount: number;
    rate: number;
    code: string;
    name: string;
    startDate?: string;
    endDate?: string;
    onlineCustomerMessage: string;
    companyId?: string;
    menuCategoryAndProductIds?: MenuCategoryProducts[];
    shopIds: any;
    maxRedeemed?: number;
    noRedeemed?: number;
    oneEmailLimit?: boolean;
    fixedDiscount?: boolean;
    canApplyOnEachProduct?: boolean;
    canApplyOnWholeOrder?: boolean;
    preEnabled?: boolean;
    openPriceRate?: boolean;
    combineWithOtherDiscounts?: boolean;
    eatingOptions: any;
    disabled: boolean;
    appliedDiscountType?: APPLIED_DISCOUNT_TYPE;
    orgId?: string;
    emailOnlyDiscount?: boolean;
    allowedEmails?: string[];
    availableOn?: PosTypes[];
    campaignId?: string;
    __typename?: "Discount";
};
