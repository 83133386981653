export type HttpCodeStatus = {
    [code: number]: string;
}

export const httpCodeStatusCodes: HttpCodeStatus = {
    200: "OK",
    201: "Created",
    400: "Bad Request",
    401: "Unauthorized",
    403: "Access forbidden",
    405: "Method not allowed",
    406: "No acceptable response",
    409: "Conflict",
    413: "Payload too large",
    429: "Too many Requests",
    500: "There was an error on the server and the request could not be completed",
    501: "Not Implemented",
    502: "Bad Gateway",
    503: "Server is unavailable to handle request"
};