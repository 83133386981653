import { PaymentStatus } from "../generated/graphql";
import { CartProduct, Modifications, PriceType, StringOrNull } from "Types";
import { ComboDiscount } from "./comboDiscountTypes";
import { Allergen, DISCOUNT_TYPE } from "./productTypes";

/**
 * There is a PosTypes in Mothership which does not match this.
 * https://github.com/peter-zhu/mothership/blob/develop/src/main/java/se/qopla/mothership/model/enums/PosType.java
 *
 * The enum below should be something like `PosMode` instead based on the way it is used.
 */
export enum PosTypes {
    EXPRESS = "express",
    POS = "pos"
}

export type FixedDiscount = {
    menuId?: string;
    fixedDiscountId?: string;
    isFixedDiscountActive: boolean;
    canCombineDiscounts: boolean;
    menuProductId?: string;
    menuCategoryId?: string;
    type: DISCOUNT_TYPE;
    fixedDiscount: boolean;
    id: string;
    menuCategoryAndProductIds: any[];
    productIds: string[];
    categoryIds: string[];
    combineWithOtherDiscounts: boolean;
    name: string;
    rate?: number;
    amount?: number;
};

export type FixedDiscounts = FixedDiscount[];

export type OrderWindowContactInformation = {
    phoneNumber: string;
    name: string;
    comment: string;
    pickupTime: string;
};

export enum OrderWindowStatus {
    PENDING = "PENDING",
    IDLE = "IDLE",
    DONE = "DONE",
    HAS_STARTED_PAYING = "HAS_STARTED_PAYING",
    REQUEST_TO_JOIN = "REQUEST_TO_JOIN"
}

export enum OrderWindowOrderType {
    GROUP = "GROUP",
    SINGLE = "SINGLE"
}

export enum ActivePayingCustomerStatus {
    STARTED = "STARTED",
    COMPLETED = "COMPLETED",
    ABORTED = "ABORTED"
}

export type OrderWindowMeta = {
    id: string;
    customers: OrderWindowCustomer[];
    version: number;

    /**
     * Decides if group order has been started
     */
    groupOrderStarted: boolean;

    /**
     * Keeps track of all customers in the current group order
     */
    groupOrderNicknames: string[];
};

export type OrderWindow = {
    /**
     * The 'id' of the orderWindow
     */
    id: string;
    /**
     * The 'shopId' of the orderWindow
     */
    shopId: string;
    /**
     * The 'displayName' of the orderWindow
     */
    displayName: number | string;
    /**
     * The 'puckNo' of the orderWindow
     */
    puckNo: string | null;
    /**
     * Array of added cartProducts to the orderWindow
     */
    cartProducts: Array<CartProduct>;
    /**
     * Added discount to the orderWindow
     */
    discount: any;
    discountedIdsAndQuantity?: CartIdAndQuantity[];
    fixedDiscounts?: FixedDiscounts;
    discountedProductIds: string[];
    fixedDiscountedProductIds?: string[];
    comboDiscounts?: ComboDiscount[];
    comboDiscountedProductIds?: string[];
    /**
     * If 'true' orderWindow is takeAway
     */
    takeAway: boolean;
    /**
     * Array to hold previousCartProducts. Will be populated when extending an orderWindow
     * DT for example
     */
    previousCartProducts: CartProduct[];
    /**
     * The Mongo ID created when making the postponed order
     */
    postponeOrderId: string | null;
    /**
     * Will be populated when parking an orderWindow
     */
    contactInformation?: OrderWindowContactInformation | null;
    /**
     * The 'tableId' associated with the orderWindow
     */
    tableId?: string | null;
    /**
     * If 'true' orderWindow is being postponed
     */
    postponePayment: boolean;
    /**
     * If 'true' orderWindow will be hidden in tabs
     */
    shouldHide?: boolean;
    /**
     * The 'comment' of the orderWindow
     */
    comment?: string | null;
    /**
     * The 'pickupTime' of the orderWindow
     */
    pickupTime?: string | null;
    /**
     * OrderWindow marked as deleted in the database
     */
    deleted: boolean;
    /**
     * ID of orderWindow who created this split orderWindow
     */
    fromSplitOrderWindowId: string | null;

    /**
     * Array of customers and their individual cartProducts
     * Used for online's table service
     */
    customers?: OrderWindowCustomer[];

    /**
     * Tip of orderWindow
     */
    tip: number;

    /**
     * If 'true' we know orderWindow has to be published
     * rename to isGroupOrder
     */
    isFromSocket: boolean;

    /**
     * Payment status of the orderWindow
     */
    paymentStatus?: PaymentStatus;

    /**
     * Decides if group order has been started
     */
    groupOrderStarted?: boolean;

    /**
     * Keeps track of all customers in the current group order
     */
    groupOrderNicknames?: string[];

    /**
     * The orderedOrderIds keep track of the orders which have been ordered through the GROUP_ORDER process
     * This field is not otherwise populated
     */
    orderedOrderIds?: string[];

    /**
     * The paidOrderIds keep track of the orders which have been paid through the GROUP_ORDER process
     * This field is not otherwise populated
     */
    paidOrderIds?: string[];
    orderNo?: number;
};

export enum OrderWindowCustomerStatus {
    READY_TO_ORDER = "READY_TO_ORDER",
    HAS_PAID = "HAS_PAID",
    DISCONNECTED = "DISCONNECTED",
    CONNECTED = "CONNECTED",
    REQUEST_TO_JOIN = "REQUEST_TO_JOIN"
}

export enum OrderWindowActivePayingCustomerStatus {
    STARTED = "STARTED",
    COMPLETED = "COMPLETED",
    ABORTED = "ABORTED"
}

export type OrderWindowCustomer = {
    nickname: string;
    clientId: string;
    paid: boolean;
    paidBy: StringOrNull;
    status: OrderWindowCustomerStatus;
};

export type CartIdAndQuantity = {
    cartId: string;
    quantity: number;
    discountType: DISCOUNT_TYPE;
};

export type DTInfo = {
    orderNumber?: string;
    orderId?: string;
};

export type PaymentTabs = Record<string, PaymentTab>;
export type PaymentTab = {
    id: string;
    displayHeaderText: string;
    displayText: string;
    status?: string;
    paymentMethod?: string;
    driveThrough: boolean;
    cancelledByShop?: boolean;
    terminalId?: string;
    postponePayment: boolean;
    DTInfo: DTInfo | null;
};

export type PosProviderRefProduct = {
    allergens: Allergen[];
    defaultPrice: number;
    id: string;
    priceType: PriceType;
    imageUrl: string;
    modifications: Modifications;
    name: string;
    refProductCategoryId: string;
    quantity: number;
};

export type PosProviderRefProductHashMap = {
    [key: string]: PosProviderRefProduct;
};
