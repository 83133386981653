import { Languages } from "Providers";

type TextOrEmpty = string | null | undefined;

export enum SEOSchemaDayOfWeek {
    MONDAY = "https://schema.org/Monday",
    TUESDAY = "https://schema.org/Tuesday",
    WEDNESDAY = "https://schema.org/Wednesday",
    THURSDAY = "https://schema.org/Thursday",
    FRIDAY = "https://schema.org/Friday",
    SATURDAY = "https://schema.org/Saturday",
    SUNDAY = "https://schema.org/Sunday"
}

export enum ActionPlatform {
    Desktop = "http://schema.org/DesktopWebPlatform",
    Mobile =  "http://schema.org/MobileWebPlatform"
}

/**
 * * Always inc. "@type" & "@context"
 * 
 * * headling - main h1 tag on page
 */
export type SEOWebPage = {
    "@context": "https://schema.org";
    "@type": "WebPage";
    name: TextOrEmpty;
    description: TextOrEmpty;
    url: TextOrEmpty;
    headline: TextOrEmpty;
    breadcrumb?: SEOBreadcrumbList;
    mainEntity?: SEOItemList;
    primaryImageOfPage?: SEOImageObject;
};

/**
 * * Always inc. "@type"
 * 
 * * Date published format "2023-09-21"
 */
export type SEOImageObject = {
    "@type": "ImageObject";
    name?: TextOrEmpty
    contentLocation?: TextOrEmpty;
    datePublished?: TextOrEmpty;
    description?: TextOrEmpty
    contentUrl: string;
};

/**
 *  * Always inc. "@type" & "@context"
 * 
 *  * USE FOR COMPANY LANDING PAGE
 * 
 *  * Same As - urls to social media
 */
export type SEOOrganization = {
    "@context": "https://schema.org";
    "@type": "Organization";
    name: TextOrEmpty;
    description: TextOrEmpty;
    url: TextOrEmpty;
    logo: TextOrEmpty;
    image: TextOrEmpty;
    telephone?: TextOrEmpty;
    email?: TextOrEmpty;
    sameAs?: string[];
    address?: SEOAddress;
    potentialAction?: SEOViewAction;
};

/**
 * * Always inc. "@type"
 * 
 */
export type SEOViewAction = {
    "@type": "ViewAction",
    target: SEOEntryPoint[];
}

/**
 * * Always inc. "@type"
 * 
 * * inLangauge = CountryLocaleId e.g sv-SE
 * 
 * * urlTemplate - point to the full url in this case will be restaurant url
 */
export type SEOEntryPoint = {
    "@type": "EntryPoint";
    urlTemplate: string;
    actionPlatform: ActionPlatform[];
    inLanguage?: string
};

/**
 * 
 * * Text object for languages
 * 
 * * Add to options when needed
 * 
 */
export type SEOTextObject = {
    [key in Languages]?: string;
}


/**
 *  * Always inc. "@type" , "@id" (url), "@context"
 *
 *  * addressCountry - region on locale e.g SE
 *
 *  * currenciesAccepted - locale currency e.g SEK
 *
 *  * paymentAccepted - "Credit Card, Swish"
 *
 *  * image - banner url
 */
export type SEORestaurant = {
    "@context": "https://schema.org"; // schema url "https://schema.org"
    "@type": "Restaurant";
    "@id": string; // url
    name: TextOrEmpty;
    description: TextOrEmpty | SEOTextObject;
    url: TextOrEmpty;
    telephone: TextOrEmpty;
    menu: TextOrEmpty; // url
    servesCuisine?: string[] | null | undefined;
    currenciesAccepted?: TextOrEmpty; // SEK / EUR
    paymentAccepted?: TextOrEmpty; // "Cash, Credit Card, Swish"
    priceRange?: TextOrEmpty; // SEK
    logo: TextOrEmpty; // Url
    image: TextOrEmpty; // banner url
    email?: TextOrEmpty;
    address?: SEOAddress;
    aggregateRating?: SEOAggregateRating;
    openingHoursSpecification: SEOOpeningHoursSpecification[];
    reviews?: SEOReview[];
};

/**
 *  * Always inc. "@type"
 *
 *  * addressCountry - region on locale e.g SE
 *
 *  * addressLocality - city / town
 */
export type SEOAddress = {
    "@type": "PostalAddress";
    addressCountry: TextOrEmpty; // "SE"
    streetAddress: TextOrEmpty;
    addressLocality: TextOrEmpty; // city
    postalCode: TextOrEmpty;
};

/**
 *  * Always inc. "@type"
 */
export type SEOAggregateRating = {
    "@type": "AggregateRating";
    ratingValue: TextOrEmpty;
    ratingCount: TextOrEmpty;
    bestRating: TextOrEmpty;
    worstRating: TextOrEmpty;
};

/**
 * * Always inc. "@type"
 *
 * TIME FORMAT - "09:00:00"
 *
 * DAY OF WEEK - e.g SEOSchemaDayOfWeek.MONDAY
 */
export type SEOOpeningHoursSpecification = {
    "@type": "OpeningHoursSpecification";
    dayOfWeek: SEOSchemaDayOfWeek;
    opens: string;
    closes: string;
};

/**
 * * Always inc. "@type"
 *
 * * Date format - "2023-09-19"
 */
export type SEOReview = {
    "@context": "https://schema.org";
    "@type": "Review";
    author: SEOPerson;
    datePublished: string;
    reviewBody: string;
    reviewRating: SEORating;
};

/**
 * * Always inc. "@type"
 */
export type SEOPerson = {
    "@type": "Person";
    name: string;
};

/**
 * * Always inc. "@type"
 */
export type SEORating = {
    "@type": "Rating";
    bestRating: TextOrEmpty;
    ratingValue: TextOrEmpty;
    worstRating: TextOrEmpty;
};

/**
 * NOTE: This is a separate context
 *
 * * Always inc. "@type"
 *
 */
export type SEOItemList = {
    "@context": "https://schema.org"; // schema url "https://schema.org"
    "@type": "ItemList";
    url: string; // just point to normal url
    numberOfItems: string;
    itemListElement: SEOListItem[];
};

/**
 * * Always inc. "@type"
 * 
 * * Use for nested paths eg home / about
 */
export type SEOBreadcrumbList = {
    "@type": "BreadcrumbList",
    itemListElement: SEOListItem[];
}

/**
 * * Always inc. "@type"
 * 
 * * item - can be seo product or string or add another type
 */
export type SEOListItem = {
    "@type": "ListItem",
    position: TextOrEmpty; // can have an order here
    item: SEOProduct | string; // can actually be anything so add type when needed
}

/**
 * * Always inc. "@type"
 * 
 */
export type SEOProduct = {
    "@context": "https://schema.org"; // schema url "https://schema.org"
    "@type": "Product";
    image: string;
    name: string;
    description: string;
    category?: TextOrEmpty;
    aggregateRating?: SEORating;
    offers?: SEOOffer;
};

/**
 * * Always inc. "@type"
 * 
 * * priceCurrency = "SEK" / "EUR"
 */
export type SEOOffer = {
    "@type": "Offer";
    priceCurrency: TextOrEmpty;
    price: string;
};