
export type Base64ImgDTO = {
    companyId: string;
    imageFolder: string;
    fileName: string;
    base64String: string;
    width: number;
    height: number;
    imageRatio: number;
    subFolder: string;
    aspectRatio?: number
};

export type ImageFile = {
    companyId: string;
    imageFolder: string;
    fileName: string;
    file: File
}

export enum ASPECT_RATIO {
    "16 / 9" = 1.77777,
    "4 / 3" = 1.33333,
    "3 / 2" = 1.5,
    "1 / 1" = 1,
    "none" = 0
}

export enum IMAGE_FORMAT {
    JPEG = "jpeg",
    PNG = "png",
    JPG = "jpg",
    GIF = "gif",
    SVG = "svg",
    WEBP = "webp",
    BMP = "bmp",
    TIFF = "tiff",
    JFIF = "jfif"
}
