export enum OptOutEmail {
    CUSTOMER_FEEDBACK = "CUSTOMER_FEEDBACK",
    READY_TO_PICK_UP = "READY_TO_PICK_UP",
    ORDER_CONFIRMATION = "ORDER_CONFIRMATION",
    CAMPAIGNS = "CAMPAIGNS"
}

export type CustomerOptOutEmailType = {
    whichOptOut: OptOutEmail;
    hasOptedOut: boolean;
};

export type CustomerOptOutEmails = {
    id?: string;
    createdAt?: Date;
    updatedAt?: Date;
    deleted?: boolean;
    email: string;
    optOutFrom?: OptOutEmail[];
};

export type CustomerOptOutDTO = {
    encryptedId: string;
    optOutFrom: OptOutEmail;
    addOptOut: boolean;
    allShops: boolean;
    publicShopId?: string;
};
