import { FixedDiscount, Menu, StringOrNull, DeepPartial, CountryVatRates, CountryLocaleId } from "Types";
import { ActiveHour } from "./contextTypes";

//** PRODUCT ENUMS */
/*
 * TODO: EXTRACT
 * Why - We have duplicated discount types
 */
export enum DISCOUNT_TYPE {
    FIXED_DISCOUNT = "FIXED_DISCOUNT",
    APPLIED_DISCOUNT = "APPLIED_DISCOUNT",
    APPLIED_DISCOUNT_PER_PRODUCT = "APPLIED_DISCOUNT_PER_PRODUCT",
    QUANTITY_APPLIED_DISCOUNT = "QUANTITY_APPLIED_DISCOUNT",
    COMBO_DISCOUNT = "COMBO_DISCOUNT",
    PRICE_ADJUST = "PRICE_ADJUST",
    SUBSCRIPTION_DISCOUNT = "SUBSCRIPTION_DISCOUNT"
}

export enum DISCOUNT_STATE {
    NONE = "NONE",
    OFF = "OFF",
    ON = "ON"
}

export enum OPEN_PRODUCT_CATEGORIES {
    FOOD_DRINK = "FOOD_DRINK",
    PRODUCT = "PRODUCT",
    OTHER = "OTHER"
}

export enum OPEN_PRODUCT_TYPE {
    OPEN_PRODUCT = "OPEN_PRODUCT",
    OPEN_PRODUCT_REF_PRODUCT = "OPEN_PRODUCT_REF_PRODUCT",
    OPEN_PRODUCT_CATEGORY = "OPEN_PRODUCT_CATEGORY",
    OPEN_PRODUCT_MENU_CATEGORY = "OPEN_PRODUCT_MENU_CATEGORY"
}

export enum PriceType {
    PRICE_PER_UNIT = "PRICE_PER_UNIT",
    PRICE_PER_KILOGRAM = "PRICE_PER_KILOGRAM",
    PRICE_PER_HECTOGRAM = "PRICE_PER_HECTOGRAM",
    PRICE_PER_GRAM = "PRICE_PER_GRAM"
}

export enum PartnerPromotionImageType {
    CATEGORY = "CATEGORY",
    CATEGORY_AND_PRODUCTS = "CATEGORY_AND_PRODUCTS",
    /**
     * * Only added here for promotion - will need to save this somewhere at some point
     */
    CATEGORY_NAVIGATION = "CATEGORY_NAVIGATION"
}

//TODO: current enum values not known
export enum AlcoholTag {
    SPIRITS = "SPIRITS",
    WINE = "WINE",
    LIGHT_BEER = "LIGHT_BEER",
    STRONG_BEER = "STRONG_BEER",
    OTHER_ALCOHOLIC_DRINK = "OTHER_ALCOHOLIC_DRINK"
}

//** ADDITIONAL CART PRODUCT HASH VALUE TYPE */
/**
 * [type] set type for a different hashvalue id that represents what it is.
 * Only use unitPrice if it is open product or price adjust
 * @type {CartProductHashObject}
 */
export type CartProductHashObject = {
    /** type for hash value  */
    type: DISCOUNT_TYPE | OPEN_PRODUCT_TYPE;

    /** Only use unit price for open product or price adjust */
    unitPrice?: number;
};

export type HashValueOrderProduct = {
    orderProduct: Partial<Omit<OrderProduct, "modifications" | "selectedBundleProductItems">> & {
        modifications?: Modifications | Modification | null | undefined;
        selectedBundleProductItems: SelectedBundleProductItem[] | BundleProductItem[] | null | undefined;
    };
};

/** Type used for menu product or menu bundle product - constructed on clicking the product */
export type MenuProductHashValues = DeepPartial<
    Pick<CartProduct, "menuProduct" | "menuBundleProduct" | "customerMeta">
> &
    HashValueOrderProduct;

//** PRODUCT TYPES */

export type Modification = {
    [key: string]: string | number;
    [key: number]: string | number;
    name: string;
    price: number;
    addonPrice: number;
};

export type Modifications = {
    sizes: Modification[] | Modification | null;
    flavours: Modification[] | Modification | null;
};

export type Allergen = {
    id: string;
    name: string;
    desc: string;
    sortOrder: number;
};

export type SelectedBundleProduct = {
    id: string;
    isFinished: boolean;
    menuBundleProduct: MenuBundleProduct;
    orderProduct: OrderProduct;
};

export type Addon = {
    name: string;
    price: number;
    quantity: number;
    groupId?: string;
};

export type IngredientLimiter = {
    limit: number;
    sortOrder: number;
    ingredient: Addon;
};

export type ProductIngredientGroup = {
    ingredients: IngredientLimiter[];
    displayByName: boolean;
    sortOrder: number;
    name: string;
    limit: number;
};

export type ProductIngredientGroups = ProductIngredientGroup[];

export type BundleProductItem = {
    name: string;
    bundleProductCategoryId: string;
    refProductId: string;
    refProductCategoryId?: string;
    kdsUnitDisplayName?: string;
    modifications?: {
        sizes: Modification[];
        flavours: Modification[];
    };
    addons: Addon[] | [];
    outOfStock: boolean;
};

export type RefProduct = {
    id: string;
    name: string;
    defaultPrice: number;
    priceType: PriceType;
    vatRate: number;
    companyId: string;
    refProductCategoryId: string;
    modifications: Modification;
    articleNumber: number;
    imageUrl: string;
    imageResizedUrls?: ResizedImageUrls;
    ingredients?: any;
    allergens: Allergen[];
    description: string;
    contents?: string;
    productTags?: ProductTags;
};

export type ProductTags = {
    alcoholTag: AlcoholTag | string;
    volumeInCl?: number;
};

export type ResizedImageUrls = {
    small?: string;
    medium?: string;
    large?: string;
};

export type RefBundleProduct = {
    id: string;
    name: string;
    description: string;
    contents?: string;
    vatRate: number;
    companyId: string;
    refProductCategoryId: string;
    articleNumber: number;
    imageUrl: string;
    imageResizedUrls?: ResizedImageUrls;
    bundleProductCategories: BundleProductCategory[];
    allergens: Allergen[];
    defaultPrice: number;
    priceType: PriceType;
    productTags?: ProductTags;
};

export type RefProductCategory = {
    id: string;
    name: string;
    companyId: string;
    description: string;
    sortOrder: number;
};

export type LocalSubscriptionMeta = {
    subscriptionId: string;
    amountDiscount?: number;
    percentageDiscount?: number;
    refProductId: string;
};

export type MenuProduct = {
    id: string;
    refProduct: RefProduct;
    price: number;
    sortOrder: number;
    color?: string;
    modifications: Modification;
    activeHours: ActiveHour[];
    productQuantity: number;
    isOutOfStock: boolean;
    subscriptionProductMeta?: LocalSubscriptionMeta;
};

export type MenuBundleProduct = {
    id: string;
    price: number;
    sortOrder: number;
    color?: string;
    activeHours: ActiveHour[];
    refBundleProduct: RefBundleProduct;
    menuBundleModifications?: MenuBundleModifications;
    subscriptionProductMeta?: LocalSubscriptionMeta;
};

export type MenuBundleModification = {
    refProductId: string;
    modifications: Modifications;
};

export type MenuBundleModifications = MenuBundleModification[];

export type BundleProductCategory = {
    id: string;
    limit: number;
    name: string;
    kdsUnitDisplayName?: string;
    refProductIdList: string[];
    color?: string;
    productQuantity: number;
    menuBundleModifications: any[];
    isOutOfStock: boolean;
    activeHours: any[];
    bundleCategoryColors: any[];
    sortOrder: number;
};

export type MenuProductCategory = {
    id: string;
    name: string;
    sortOrder: number;
    color?: string;
    upsellCategory: boolean;
    upsellCategoryAtPayment: boolean;
    menuProducts: MenuProduct[];
    menuBundleProducts: MenuBundleProduct[];
    open?: boolean;
    description?: string;
    imageUrl?: string;
    imageResizedUrls?: ResizedImageUrls;
    isMobile?: boolean;
    isSubscriptionCategory?: boolean;
    posFavorite: boolean;
    partnerPromotionImageType?: PartnerPromotionImageType;
};

export type SelectedBundleProductItem = {
    addons: Addon[] | [];
    bundleProductCategoryId: string;
    kdsUnitDisplayName?: string | null;
    modifications?: Modifications | null;
    modificationsToUse?: Modifications | null;
    name: string;
    refProductCategoryId: string | null;
    refProductId: string | null;
    outOfStock: boolean;
};

export type OpenProduct = {
    companyId: string;
    shopId: string;
    totalPrice: number;
    vatRate: number;
    menuId: string;
    modification: Modification;
    type: OPEN_PRODUCT_CATEGORIES;
};

export type CombinedDiscount = {
    discountId: string;
    name: string;
    code?: string;
    discountValue: number;
    discountRate?: number;
    discountedFrom?: number;
    discountOrder?: number;
    discountUpsold?: boolean;
    quantityUsedForDiscount?: number;
    comboUpsellValue?: number;
    discountType?: DISCOUNT_TYPE | string;
};

export type ComboDiscountValue = {
    discountId: string;
    totalPriceDiscounted: number;
    totalCartDiscountValue: number;
    quantityUsed: number;
    totalDiscountValue: number;
    discountedRatio: number;
    vatRat: number;
};

export type DiscountUpsold = {
    discountId: string;
    productIds: string[];
    previousProductIds: string[];
};

export type UpsellDiscountOrderWindow = {
    orderWindowId: string;
    inSplitMode?: boolean;
    upsoldDiscounts: DiscountUpsold[];
};

export type KitchenOrderProduct = {
    hash: number;
    price: number;
    menuCategoryId: string;
    comboDiscountValues?: ComboDiscountValue[];
    modifications?: {
        flavours?: Modification;
        sizes?: Modification;
    };
    name: string;
    quantity: number;
    weight: number;
    priceType: PriceType;
    refProductCategoryId: string;
    refProductId?: string;
    refBundleProductId?: string;
    selectedBundleProductItems?: SelectedBundleProductItem[];
    addons: Addon[];
    comment?: string;
};
export type OrderProduct = {
    id: string;
    menuCategoryId: string;
    comboDiscountValues?: ComboDiscountValue[];
    modifications?: Modifications;
    name: string;
    quantity: number;
    weight: number;
    priceType: PriceType;
    refProductCategoryId: string;
    refProductId?: string;
    refBundleProductId?: string | null;
    selectedBundleProductItems?: SelectedBundleProductItem[] | null;
    shopId: string;
    totalNetPrice: number;
    totalPrice: number;
    unitPrice: number;
    vatRate: number;
    addons: Addon[];
    comment?: string | null;
    totalComboUpsellValue?: number;
    discountIds?: string[];
    discountValue?: number;
    discountRate?: number;
    combinedDiscounts?: CombinedDiscount[];
    upsoldDiscountIds?: string[];
    isStockTracked: boolean;
    isOpenProduct?: boolean;
    isLastOrderProduct?: boolean;
    __typename?: "OrderProduct";
};

export type OnlineFixedDiscount = {
    subscriptionProductMeta?: LocalSubscriptionMeta;
} & FixedDiscount;

export enum CustomerMetaStatus {
    ADDED = "ADDED",
    ORDERED = "ORDERED",
    PAID = "PAID"
}

export type CustomerMeta = {
    addedBy: string;
    paidBy: StringOrNull;
    status: CustomerMetaStatus;
};

export type CartProduct = {
    id: string;
    fixedDiscount?: OnlineFixedDiscount;
    menuProduct?: MenuProduct;
    menuBundleProduct?: MenuBundleProduct;
    oldId?: string;
    isFinished?: boolean;
    orderProduct: OrderProduct;
    updatedUnitPrice?: boolean;
    customerMeta?: CustomerMeta;
    idBeforeEdit?: string;
};

export type OnlineProduct = {
    /**
     * 'id' comes from MenuProduct or MenuBundleProduct
     */
    id: string;
    isOutOfStock: boolean;
    price: number;
    productQuantity: number;
    sortOrder: number;
    refBundleProduct?: RefBundleProduct;
    refProduct?: RefProduct;
    menuBundleProduct?: MenuBundleProduct;
    menuProduct?: MenuProduct;
    modifications?: Modification;
    type: "REF_PRODUCT" | "REF_BUNDLE_PRODUCT";
    allergens: Allergen[];
    description: string;
    activeHours: ActiveHour[];
    subscriptionProductMeta?: LocalSubscriptionMeta;
    menuBundleModifications?: MenuBundleModifications;
    isStockTracked: boolean;
};

export type OnlineProducts = OnlineProduct[];

export type OnlineProductCategory = {
    id: string;
    name: string;
    description?: string;
    imageUrl?: string;
    onlineProducts: OnlineProduct[];
    isSubscriptionCategory?: boolean;
    sortOrder: number;
    upsellCategory: boolean;
    upsellCategoryAtPayment: boolean;
    ref: any;
    partnerPromotionImageType?: PartnerPromotionImageType | undefined;
};

export type OnlineProductCategories = OnlineProductCategory[];

export type OnlineMenu = {
    onlineProductCategories: OnlineProductCategories;
    onlineUpsellCategoryAtPayment: OnlineProductCategory | null;
    onlineUpsellCategory: OnlineProductCategory | null;
    isInactive?: boolean;
} & Omit<Menu, "allowTopSeller" | "menuProductCategories">;

export type OnlineMenus = OnlineMenu[];

/**
 * * headerImageUrl = on top of banner
 *
 * * menuProductImageUrl = on product tile
 *
 * * promotedCategoryImageUrl = on category promotion (e.g Drinks / but no image on product tile)
 *
 * * partnerProductsImageUrl = on category that has the menuProductImageUrl
 *
 * * menuCategoryNavigationImageUrl = on category navigation (can be an Alternative to company logo url) / could also
 * be same as partnerProductsImageUrl
 */
export type PartnerPromotion = {
    id: string;
    partnerName: string;
    headerImageUrl?: string;
    menuProductImageUrl?: string;
    partnerProductsImageUrl?: string;
    promotedCategoryImageUrl?: string;
    menuCategoryNavigationImageUrl?: string;
    companyLogoUrl?: string;
};

export type HashRefProductType = { [id: string]: RefProduct };
