export type AlphaKeys = "50" | "100" | "200" | "300" | "400" | "500" | "600" | "700" | "800" | "900";

export type Opacity = "0.04" | "0.06" | "0.08" | "0.16" | "0.24" | "0.36" | "0.48" | "0.64" | "0.80" | "0.92";

export const HEX_REGEX = /^#([A-Fa-f0-9]{3}|[A-Fa-f0-9]{4}|[A-Fa-f0-9]{6}|[A-Fa-f0-9]{8})$/;
export const RGBA_REGEX = /^rgba?\((\d+),\s*(\d+),\s*(\d+),?\s*(\d*\.?\d*)?\)$/;

export type ColorThemes = {
    [key: string]: string | { [key in AlphaKeys]?: string };
};
