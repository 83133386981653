import { IngredientLimiter } from "./productTypes";
import { PosOrderDTOInput } from "./inputs";

export type CommonPOSPaymentParams = {
    paymentMethodAmount: {
        amount: number;
        paymentMethod: string;
    };
    orderInput: PosOrderDTOInput;
    activeOrderWindowId: string;
    paymentFuncs: {
        createPosOrder: Function;
        payPostponedPaymentOrder: Function;
        createPostponedPaymentOrder: Function;
        createCloudPosOrder: Function;
        makeCloudPurchase: Function;
        cloudPurchase: Function;
        apiToken: string;
    };
    receiptPrinter: {
        ip: string;
        deviceName: string;
        usbLegacyPrinter: boolean;
    };
    optionalReceipts: boolean;
    confirmOptionalReceipts: () => boolean;
    postponedInfo: any | null;
    restaurantOnlineOrderUrl: string | null;
    updateActivePaymentTabStatus: (
        paymentTabId: string,
        operationSuccess: boolean,
        newDisplayHeaderText: string,
        newDisplayText: string,
        newStatus: string
    ) => void;
};

export type Terminal = {
    id?: string;
    name: string;
    description: string;
    terminalId: string;
    shopId: string;
    cloudEnabled: boolean;
    cloudRESTEnabled: boolean;
    hostAddress: string;
};

export enum BundleItemOperation {
    ADD = "ADD",
    REPLACE = "REPLACE",
    DECREMENT = "DECREMENT",
    REMOVE = "REMOVE",
    RESET_ALL_CATEGORIES = "RESET_ALL_CATEGORIES",
    RESET_CATEGORY = "RESET_CATEGORY",
    RESET_BUNDLE_ITEM = "RESET_BUNDLE_ITEM"
}

export enum AddonOperation {
    ADD = "ADD",
    INCREMENT = "INCREMENT",
    DECREMENT = "DECREMENT",
    RESET = "RESET"
}

export type Ingredients = {
    limit: number;
    sortOrder: number;
    ingredient: {
        name: string;
        groupId: string;
        price: number;
    };
}[];

export type AddonsHashMapValue = {
    name: string;
    limit: number;
    ingredients: Ingredients;
    sortOrder: number;
    displayByName: boolean;
}[];

export type AddonsHashMapItem = {
    name: string;
    limit: number;
    ingredients: Ingredients;
    sortOrder: number;
    displayByName: boolean;
};

export type AddonsHashMap = {
    [key: string]: AddonsHashMapValue;
};

export type AddonsHashMapInput = {
    displayByName: boolean;
    id: string;
    name: string;
    limit: number;
    refProductIds: string[];
    sortOrder: number;
    ingredientLimiters: IngredientLimiter[];
};
