import { EatingPreference } from "Types";

export const QUICK_SERVE = "QUICK_SERVE";

export type PICKUP_EATING_OPTIONS =
    | EatingPreference.CATERING
    | EatingPreference.HOME_DELIVERY
    | EatingPreference.TAKE_AWAY
    | EatingPreference.EAT_HERE
    | typeof QUICK_SERVE;
