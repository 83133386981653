import { ActiveHour, AvailablePosTypes, BillingInfo, ContactInformation, CountryLocale, EatingOption } from "Types";
export type Base64File = {
    filename: string;
    base64String: string;
};

export enum CustomerRegistrationStatus {
    DRAFT = "DRAFT",
    DONE = "DONE"
}

export const QoplaServices = {
    ...AvailablePosTypes,
    PICKUP_DISPLAY: "PICKUP_DISPLAY"
};
2;

export type QoplaServicesType = keyof typeof QoplaServices;

export type CustomerRegistration = {
    id: string;
    registeredBy: string;
    status: CustomerRegistrationStatus;
    billingAddressSameAsCompanyAddress: Boolean;
    payoutEmailSameAsContactEmail: Boolean;

    /**Shop information */
    shopBillingInfo: BillingInfo;
    shopContactInformation: ContactInformation;
    shopActiveHours: ActiveHour[];
    shopName: string;
    shopOrganisationNumber: string;

    /**Company information */
    companyName: string;
    companyId: string;
    countryLocale: CountryLocale;
    vatRates: number[];
    standardProductVatRate: number;
    companyContactInformation: ContactInformation;

    /** Hardware info  */
    numCashDrawers: Number;
    numSmallPOS: Number;
    numMediumPOS: Number;
    numLargePOS: Number;
    numReceiptPrinters: Number;
    numExpressOnlineTable: Number;
    numExpressOnlineFloor: Number;
    numLegacyExpressTable: Number;
    numLegacyExpressFloor: Number;
    numOrderDisplayTablets: Boolean;
    numOrderDisplayWithBuiltInComputer: Number;
    numPickupDisplays: Number;
    otherHardwareNotes: String;
    numBusinessCards: Number;
    expressPOS: Number; // Remove?
    numSunmi: Number;

    /**
     * Homepage information
     */
    // For redirecting to Qopla
    wantsQoplaHomepage: Boolean;
    webpageUrl: String;
    // For modifying the clients own homepage with a button to Qopla shops
    wantsButtonsOnOwnHomepage: String;
    ftpHost: String;
    ftpUsername: String;
    ftpPassword: String;

    /**
     *  NEW Menu information
     */
    needFullMenu: Boolean;
    needPartialMenu: Boolean;
    needNoMenu: Boolean;
    needDeliveryMenu: Boolean;
    otherMenuNotes: String;

    /** Extra registration information */
    contractSigned: Boolean;
    agreedLastStartDate: Date;
    posTypes: QoplaServicesType[];
    eatingOptions: EatingOption[];
    messageToQopla: String;
    images: Base64File[];

    thirdPartyIntegrations: String[];
    sameThirdPartyMenuPrices: boolean | null;
    staffManagementIntegrations: String[];
    accountingIntegrations: String[];
    shopCreation: boolean;
    updatedAt: Date;
};
