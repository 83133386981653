import { SVGObjectType } from "Constants";
import { StringOrNull } from "./utilTypes";

export type TableMode = Table;
export type ShopTable = Table;

export enum Editable {
    WALL = "WALL",
    TABLE = "TABLE",
    NONE = "NONE"
}

export enum ModeFlag {
    EDIT = "EDIT",
    DRAW = "DRAW",
    VIEW = "VIEW"
}

export interface TableExtended extends Table {
    isDragging: boolean;
    rotation: number;
}

export interface SVGElement {
    attrs: {
        id: string;
        x: number;
        y: number;
        [key: string]: any;
    };
    className: string;
    [key: string]: any;
}

export interface DrawBuffer {
    startPointX: number;
    startPointY: number;
    width: number;
    height: number;
}

export interface Wall {
    id: any;
    coordinateX: number;
    coordinateY: number;
    width: number;
    height: number;
    isDragging?: boolean;
}

export interface Table {
    id: StringOrNull;
    posId?: StringOrNull;
    tableId?: StringOrNull; // For group online order
    name: string;
    description: string | null;
    coordinateX: number;
    coordinateY: number;
    width: number;
    height: number;
    occupied: boolean;
    objectType: SVGObjectType;
    shape: TableShape;
    isDragging?: boolean;
    deleted: boolean;
}

export enum TableShape {
    RECT = "RECT",
    CIRCLE = "CIRCLE"
}

export interface History {
    applied: boolean;
    refState: any;
    refSetter: any;
    change: {
        id: string | number;
        startPointX: number;
        startPointY: number;
        width: number;
        height: number;
        deleted?: boolean;
    };
    created?: boolean;
}
