import { Addon, Modifications, SelectedBundleProductItem } from "Types";

export type LastOrderDTO = {
    orderId: string;
    purchaseDate: Date;
    lastOrderProducts?: LastOrderProduct[] | null;
}

export type LastOrderProduct = {
    name: string;
    menuIds: string[] | undefined | null;
    refProductId?: string;
    menuCategoryId?: string;
    refBundleProductId?: string | null;
    refProductCategoryId: string;
    addons: Addon[] | undefined | null;
    selectedBundleProductItems?: SelectedBundleProductItem[] | null;
    modifications?: Modifications;
    quantity: number;
    comment?: string | null;
    subscriptionProduct: boolean;
    upsell: boolean;
    totalPrice: number;
};

export type LastOrderProductExtras = {
    hasMatchingModsOrNoMods: boolean;
    selectedModifications: any | null;
    addons: Addon[];
    missingModifications: string[];
};

export enum LastOrderProductReason {
    MISSING_PRODUCT = "MISSING_PRODUCT",
    MISSING_MODIFICATIONS = "MISSING_MODIFICATIONS",
    MISSING_BUNDLE_ITEM_MODIFICATIONS = "MISSING_BUNDLE_ITEM_MODIFICATIONS",
    MISSING_BUNDLE_ITEM = "MISSING_BUNDLE_ITEM",
    BUNDLE_ITEM_OUT_OF_STOCK = "BUNDLE_ITEM_OUT_OF_STOCK",
    OUT_OF_STOCK = "OUT_OF_STOCK",
    OUT_OF_STOCK_ADDONS = "OUT_OF_STOCK_ADDONS",
    OUT_OF_STOCK_ADDONS_BUNDLE = "OUT_OF_STOCK_ADDONS_BUNDLE",
    OUT_OF_STOCK_ADDONS_SELECTED_BUNDLE_ITEM = "OUT_OF_STOCK_ADDONS_SELECTED_BUNDLE_ITEM",
    INCLUDED_MISSING_ADDONS = "INCLUDED_MISSING_ADDONS",
    INCLUDED_BUNDLE_ITEM_MISSING_ADDONS = "INCLUDED_BUNDLE_ITEM_MISSING_ADDONS",
    NOT_IN_MENU = "NOT_IN_MENU",
    MENU_NOT_ACTIVE = "MENU_NOT_ACTIVE"
}

export type LastOrderIssue = {
    id: string;
    lastOrderProductReason: LastOrderProductReason;
    lastOrderProduct: LastOrderProduct;
    productId: string;
    addonOrModificationIssues?: string[];
};